define('prison-rideshare-ui/controllers/debts', ['exports'], function (exports) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });

   var __cov_bzz_1rbyuLyTBOPtJLTpMg = Function('return this')();
   if (!__cov_bzz_1rbyuLyTBOPtJLTpMg.__coverage__) {
      __cov_bzz_1rbyuLyTBOPtJLTpMg.__coverage__ = {};
   }
   __cov_bzz_1rbyuLyTBOPtJLTpMg = __cov_bzz_1rbyuLyTBOPtJLTpMg.__coverage__;
   if (!__cov_bzz_1rbyuLyTBOPtJLTpMg['app/controllers/debts.js']) {
      __cov_bzz_1rbyuLyTBOPtJLTpMg['app/controllers/debts.js'] = { "path": "app/controllers/debts.js", "s": { "1": 0 }, "b": {}, "f": { "1": 0 }, "fnMap": { "1": { "name": "(anonymous_1)", "line": 5, "loc": { "start": { "line": 5, "column": 13 }, "end": { "line": 5, "column": 20 } } } }, "statementMap": { "1": { "start": { "line": 6, "column": 6 }, "end": { "line": 6, "column": 34 } } }, "branchMap": {}, "code": ["import Controller from '@ember/controller';", "", "export default Controller.extend({", "  actions: {", "    reimburse(debt) {", "      return debt.destroyRecord();", "    }", "  }", "});", ""] };
   }
   __cov_bzz_1rbyuLyTBOPtJLTpMg = __cov_bzz_1rbyuLyTBOPtJLTpMg['app/controllers/debts.js'];
   var Controller = Ember.Controller;
   exports.default = Controller.extend({ actions: {
         reimburse: function reimburse(debt) {
            __cov_bzz_1rbyuLyTBOPtJLTpMg.f['1']++;__cov_bzz_1rbyuLyTBOPtJLTpMg.s['1']++;return debt.destroyRecord();
         }
      } });
});