define('prison-rideshare-ui/utils/reason-to-icon', ['exports'], function (exports) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });

   var __cov_vsAhSLE5O8bF8tsbm0MbRQ = Function('return this')();
   if (!__cov_vsAhSLE5O8bF8tsbm0MbRQ.__coverage__) {
      __cov_vsAhSLE5O8bF8tsbm0MbRQ.__coverage__ = {};
   }
   __cov_vsAhSLE5O8bF8tsbm0MbRQ = __cov_vsAhSLE5O8bF8tsbm0MbRQ.__coverage__;
   if (!__cov_vsAhSLE5O8bF8tsbm0MbRQ['app/utils/reason-to-icon.js']) {
      __cov_vsAhSLE5O8bF8tsbm0MbRQ['app/utils/reason-to-icon.js'] = { "path": "app/utils/reason-to-icon.js", "s": { "1": 0 }, "b": {}, "f": {}, "fnMap": {}, "statementMap": { "1": { "start": { "line": 1, "column": 0 }, "end": { "line": 14, "column": 2 } } }, "branchMap": {}, "code": ["const reasonToIcon = {", "  'lockdown': 'lock',", "  'visitor': 'perm identity',", "  'no car': 'directions car',", "  'driver not found': 'assignment ind',", "  'weather': 'cloud',", "  'transfer': 'shuffle',", "  'error': 'error',", "  'visitor missing': 'perm identity',", "  'driver missing': 'directions car',", "  'driver cancelled': 'hot tub',", "  'released': 'lock open',", "  'jail cancelled': 'alarm off',", "};", "", "export default reasonToIcon;", ""] };
   }
   __cov_vsAhSLE5O8bF8tsbm0MbRQ = __cov_vsAhSLE5O8bF8tsbm0MbRQ['app/utils/reason-to-icon.js'];
   __cov_vsAhSLE5O8bF8tsbm0MbRQ.s['1']++;var reasonToIcon = { 'lockdown': 'lock', 'visitor': 'perm identity', 'no car': 'directions car', 'driver not found': 'assignment ind', 'weather': 'cloud', 'transfer': 'shuffle', 'error': 'error', 'visitor missing': 'perm identity', 'driver missing': 'directions car', 'driver cancelled': 'hot tub', 'released': 'lock open', 'jail cancelled': 'alarm off' };exports.default = reasonToIcon;
});