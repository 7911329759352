define('prison-rideshare-ui/controllers/register', ['exports'], function (exports) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });

   var __cov_2MTYi3LuVTRfgW0zMxT9ZQ = Function('return this')();
   if (!__cov_2MTYi3LuVTRfgW0zMxT9ZQ.__coverage__) {
      __cov_2MTYi3LuVTRfgW0zMxT9ZQ.__coverage__ = {};
   }
   __cov_2MTYi3LuVTRfgW0zMxT9ZQ = __cov_2MTYi3LuVTRfgW0zMxT9ZQ.__coverage__;
   if (!__cov_2MTYi3LuVTRfgW0zMxT9ZQ['app/controllers/register.js']) {
      __cov_2MTYi3LuVTRfgW0zMxT9ZQ['app/controllers/register.js'] = { "path": "app/controllers/register.js", "s": { "1": 0, "2": 0, "3": 0, "4": 0, "5": 0, "6": 0 }, "b": {}, "f": { "1": 0 }, "fnMap": { "1": { "name": "(anonymous_1)", "line": 9, "loc": { "start": { "line": 9, "column": 12 }, "end": { "line": 9, "column": 20 } } } }, "statementMap": { "1": { "start": { "line": 10, "column": 6 }, "end": { "line": 10, "column": 29 } }, "2": { "start": { "line": 12, "column": 6 }, "end": { "line": 12, "column": 37 } }, "3": { "start": { "line": 14, "column": 6 }, "end": { "line": 23, "column": 9 } }, "4": { "start": { "line": 15, "column": 8 }, "end": { "line": 19, "column": 10 } }, "5": { "start": { "line": 21, "column": 8 }, "end": { "line": 21, "column": 115 } }, "6": { "start": { "line": 22, "column": 8 }, "end": { "line": 22, "column": 37 } } }, "branchMap": {}, "code": ["import { inject as service } from '@ember/service';", "import Controller from '@ember/controller';", "import { getWithDefault } from '@ember/object';", "", "export default Controller.extend({", "  session: service(),", "", "  actions: {", "    register(event) {", "      event.preventDefault();", "", "      const user = this.get('model');", "", "      return user.save().then(() => {", "        return this.get('session').authenticate(", "          'authenticator:application',", "          user.get('email'),", "          user.get('password')", "        );", "      }).catch(error => {", "        const errorText = getWithDefault(error, 'errors.firstObject.detail', 'There was an error registering you');", "        this.set('error', errorText);", "      });", "    }", "  }", "});", ""] };
   }
   __cov_2MTYi3LuVTRfgW0zMxT9ZQ = __cov_2MTYi3LuVTRfgW0zMxT9ZQ['app/controllers/register.js'];
   var service = Ember.inject.service;
   var Controller = Ember.Controller;
   var getWithDefault = Ember.getWithDefault;
   exports.default = Controller.extend({ session: service(), actions: {
         register: function register(event) {
            var _this = this;

            __cov_2MTYi3LuVTRfgW0zMxT9ZQ.f['1']++;__cov_2MTYi3LuVTRfgW0zMxT9ZQ.s['1']++;event.preventDefault();__cov_2MTYi3LuVTRfgW0zMxT9ZQ.s['2']++;var user = this.get('model');__cov_2MTYi3LuVTRfgW0zMxT9ZQ.s['3']++;return user.save().then(function () {
               __cov_2MTYi3LuVTRfgW0zMxT9ZQ.s['4']++;return _this.get('session').authenticate('authenticator:application', user.get('email'), user.get('password'));
            }).catch(function (error) {
               __cov_2MTYi3LuVTRfgW0zMxT9ZQ.s['5']++;var errorText = getWithDefault(error, 'errors.firstObject.detail', 'There was an error registering you');__cov_2MTYi3LuVTRfgW0zMxT9ZQ.s['6']++;_this.set('error', errorText);
            });
         }
      } });
});