define('prison-rideshare-ui/components/toolbar-header', ['exports'], function (exports) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });

   var __cov_zPQb_JqO5OSGrGe7ZQ$Vhw = Function('return this')();
   if (!__cov_zPQb_JqO5OSGrGe7ZQ$Vhw.__coverage__) {
      __cov_zPQb_JqO5OSGrGe7ZQ$Vhw.__coverage__ = {};
   }
   __cov_zPQb_JqO5OSGrGe7ZQ$Vhw = __cov_zPQb_JqO5OSGrGe7ZQ$Vhw.__coverage__;
   if (!__cov_zPQb_JqO5OSGrGe7ZQ$Vhw['app/components/toolbar-header.js']) {
      __cov_zPQb_JqO5OSGrGe7ZQ$Vhw['app/components/toolbar-header.js'] = { "path": "app/components/toolbar-header.js", "s": { "1": 0, "2": 0, "3": 0, "4": 0, "5": 0 }, "b": { "1": [0, 0] }, "f": { "1": 0, "2": 0 }, "fnMap": { "1": { "name": "(anonymous_1)", "line": 10, "loc": { "start": { "line": 10, "column": 18 }, "end": { "line": 10, "column": 29 } } }, "2": { "name": "(anonymous_2)", "line": 24, "loc": { "start": { "line": 24, "column": 17 }, "end": { "line": 24, "column": 20 } } } }, "statementMap": { "1": { "start": { "line": 11, "column": 4 }, "end": { "line": 11, "column": 46 } }, "2": { "start": { "line": 13, "column": 4 }, "end": { "line": 20, "column": 5 } }, "3": { "start": { "line": 14, "column": 6 }, "end": { "line": 17, "column": 9 } }, "4": { "start": { "line": 19, "column": 6 }, "end": { "line": 19, "column": 16 } }, "5": { "start": { "line": 25, "column": 6 }, "end": { "line": 25, "column": 41 } } }, "branchMap": { "1": { "line": 13, "type": "if", "locations": [{ "start": { "line": 13, "column": 4 }, "end": { "line": 13, "column": 4 } }, { "start": { "line": 13, "column": 4 }, "end": { "line": 13, "column": 4 } }] } }, "code": ["import { computed } from '@ember/object';", "import { alias } from '@ember/object/computed';", "import { inject as service } from '@ember/service';", "import Component from '@ember/component';", "", "export default Component.extend({", "  sidebar: service(),", "  sidebarOpen: alias('sidebar.open'),", "", "  chips: computed(function() {", "    const hostname = window.location.hostname;", "", "    if (hostname.indexOf('sandbox') > -1) {", "      return [{", "        label: 'Sandbox',", "        title: 'All data on this instance is erased daily. If some type of example data would be useful for you, let Buck know.'", "      }];", "    } else {", "      return [];", "    }", "  }),", "", "  actions: {", "    toggleSidebar() {", "      this.toggleProperty('sidebarOpen');", "    }", "  }", "});", ""] };
   }
   __cov_zPQb_JqO5OSGrGe7ZQ$Vhw = __cov_zPQb_JqO5OSGrGe7ZQ$Vhw['app/components/toolbar-header.js'];
   var computed = Ember.computed;
   var alias = Ember.computed.alias;
   var service = Ember.inject.service;
   var Component = Ember.Component;
   exports.default = Component.extend({ sidebar: service(), sidebarOpen: alias('sidebar.open'), chips: computed(function () {
         __cov_zPQb_JqO5OSGrGe7ZQ$Vhw.f['1']++;__cov_zPQb_JqO5OSGrGe7ZQ$Vhw.s['1']++;var hostname = window.location.hostname;__cov_zPQb_JqO5OSGrGe7ZQ$Vhw.s['2']++;if (hostname.indexOf('sandbox') > -1) {
            __cov_zPQb_JqO5OSGrGe7ZQ$Vhw.b['1'][0]++;__cov_zPQb_JqO5OSGrGe7ZQ$Vhw.s['3']++;return [{ label: 'Sandbox', title: 'All data on this instance is erased daily. If some type of example data would be useful for you, let Buck know.' }];
         } else {
            __cov_zPQb_JqO5OSGrGe7ZQ$Vhw.b['1'][1]++;__cov_zPQb_JqO5OSGrGe7ZQ$Vhw.s['4']++;return [];
         }
      }), actions: {
         toggleSidebar: function toggleSidebar() {
            __cov_zPQb_JqO5OSGrGe7ZQ$Vhw.f['2']++;__cov_zPQb_JqO5OSGrGe7ZQ$Vhw.s['5']++;this.toggleProperty('sidebarOpen');
         }
      } });
});