define('prison-rideshare-ui/utils/anonymise-address', ['exports'], function (exports) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });
   exports.default = anonymiseAddress;

   var __cov_dGkbJ2ugju60tLiWYWz$Dg = Function('return this')();
   if (!__cov_dGkbJ2ugju60tLiWYWz$Dg.__coverage__) {
      __cov_dGkbJ2ugju60tLiWYWz$Dg.__coverage__ = {};
   }
   __cov_dGkbJ2ugju60tLiWYWz$Dg = __cov_dGkbJ2ugju60tLiWYWz$Dg.__coverage__;
   if (!__cov_dGkbJ2ugju60tLiWYWz$Dg['app/utils/anonymise-address.js']) {
      __cov_dGkbJ2ugju60tLiWYWz$Dg['app/utils/anonymise-address.js'] = { "path": "app/utils/anonymise-address.js", "s": { "1": 1, "2": 0, "3": 0, "4": 0 }, "b": {}, "f": { "1": 0 }, "fnMap": { "1": { "name": "anonymiseAddress", "line": 1, "loc": { "start": { "line": 1, "column": 15 }, "end": { "line": 1, "column": 50 } } } }, "statementMap": { "1": { "start": { "line": 1, "column": 15 }, "end": { "line": 10, "column": 1 } }, "2": { "start": { "line": 2, "column": 2 }, "end": { "line": 9, "column": 34 } }, "3": { "start": { "line": 4, "column": 47 }, "end": { "line": 4, "column": 73 } }, "4": { "start": { "line": 5, "column": 35 }, "end": { "line": 5, "column": 82 } } }, "branchMap": {}, "code": ["export default function anonymiseAddress(address) {", "  return address", "    .trim()", "    .replace(/^#?(\\d+-\\d+)/, (dashedNumber) => dashedNumber.split('-')[1])", "    .replace(/^(\\d+)/, (number) => `${Math.floor(parseInt(number)/100)*100} block`)", "    .replace(/ \\(.*\\)$/, '')", "    .replace(/ #\\w*$/, '')", "    .replace(/ (unit|suite|apt) .*$/i, '')", "    .replace(/ building .*$/i, '')", "}", ""] };
   }
   __cov_dGkbJ2ugju60tLiWYWz$Dg = __cov_dGkbJ2ugju60tLiWYWz$Dg['app/utils/anonymise-address.js'];
   function anonymiseAddress(address) {
      __cov_dGkbJ2ugju60tLiWYWz$Dg.f['1']++;__cov_dGkbJ2ugju60tLiWYWz$Dg.s['2']++;return address.trim().replace(/^#?(\d+-\d+)/, function (dashedNumber) {
         __cov_dGkbJ2ugju60tLiWYWz$Dg.s['3']++;return dashedNumber.split('-')[1];
      }).replace(/^(\d+)/, function (number) {
         __cov_dGkbJ2ugju60tLiWYWz$Dg.s['4']++;return Math.floor(parseInt(number) / 100) * 100 + ' block';
      }).replace(/ \(.*\)$/, '').replace(/ #\w*$/, '').replace(/ (unit|suite|apt) .*$/i, '').replace(/ building .*$/i, '');
   }
});