define('prison-rideshare-ui/app', ['exports', 'prison-rideshare-ui/resolver', 'ember-load-initializers', 'prison-rideshare-ui/config/environment'], function (exports, _resolver, _emberLoadInitializers, _environment) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });

   var __cov_LentoWVdSZ6TTnRl6HygvQ = Function('return this')();
   if (!__cov_LentoWVdSZ6TTnRl6HygvQ.__coverage__) {
      __cov_LentoWVdSZ6TTnRl6HygvQ.__coverage__ = {};
   }
   __cov_LentoWVdSZ6TTnRl6HygvQ = __cov_LentoWVdSZ6TTnRl6HygvQ.__coverage__;
   if (!__cov_LentoWVdSZ6TTnRl6HygvQ['app/app.js']) {
      __cov_LentoWVdSZ6TTnRl6HygvQ['app/app.js'] = { "path": "app/app.js", "s": { "1": 0, "2": 0 }, "b": {}, "f": {}, "fnMap": {}, "statementMap": { "1": { "start": { "line": 6, "column": 0 }, "end": { "line": 10, "column": 3 } }, "2": { "start": { "line": 12, "column": 0 }, "end": { "line": 12, "column": 43 } } }, "branchMap": {}, "code": ["import Application from '@ember/application';", "import Resolver from './resolver';", "import loadInitializers from 'ember-load-initializers';", "import config from './config/environment';", "", "const App = Application.extend({", "  modulePrefix: config.modulePrefix,", "  podModulePrefix: config.podModulePrefix,", "  Resolver", "});", "", "loadInitializers(App, config.modulePrefix);", "", "export default App;", ""] };
   }
   __cov_LentoWVdSZ6TTnRl6HygvQ = __cov_LentoWVdSZ6TTnRl6HygvQ['app/app.js'];
   var Application = Ember.Application;
   __cov_LentoWVdSZ6TTnRl6HygvQ.s['1']++;var App = Application.extend({ modulePrefix: _environment.default.modulePrefix, podModulePrefix: _environment.default.podModulePrefix, Resolver: _resolver.default });__cov_LentoWVdSZ6TTnRl6HygvQ.s['2']++;(0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);exports.default = App;
});