define('prison-rideshare-ui/authorizers/application', ['exports', 'ember-simple-auth/authorizers/oauth2-bearer'], function (exports, _oauth2Bearer) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });

   var __cov_qnI14vvTGwmnOgpD9vQ23w = Function('return this')();
   if (!__cov_qnI14vvTGwmnOgpD9vQ23w.__coverage__) {
      __cov_qnI14vvTGwmnOgpD9vQ23w.__coverage__ = {};
   }
   __cov_qnI14vvTGwmnOgpD9vQ23w = __cov_qnI14vvTGwmnOgpD9vQ23w.__coverage__;
   if (!__cov_qnI14vvTGwmnOgpD9vQ23w['app/authorizers/application.js']) {
      __cov_qnI14vvTGwmnOgpD9vQ23w['app/authorizers/application.js'] = { "path": "app/authorizers/application.js", "s": {}, "b": {}, "f": {}, "fnMap": {}, "statementMap": {}, "branchMap": {}, "code": ["import OAuth2Bearer from 'ember-simple-auth/authorizers/oauth2-bearer';", "", "export default OAuth2Bearer.extend();", ""] };
   }
   __cov_qnI14vvTGwmnOgpD9vQ23w = __cov_qnI14vvTGwmnOgpD9vQ23w['app/authorizers/application.js'];
   exports.default = _oauth2Bearer.default.extend();
});