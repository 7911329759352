define('prison-rideshare-ui/routes/statistics', ['exports', 'prison-rideshare-ui/mixins/authenticated-route'], function (exports, _authenticatedRoute) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });

   var __cov_PELyvnJ07rp_BOx3i$yeww = Function('return this')();
   if (!__cov_PELyvnJ07rp_BOx3i$yeww.__coverage__) {
      __cov_PELyvnJ07rp_BOx3i$yeww.__coverage__ = {};
   }
   __cov_PELyvnJ07rp_BOx3i$yeww = __cov_PELyvnJ07rp_BOx3i$yeww.__coverage__;
   if (!__cov_PELyvnJ07rp_BOx3i$yeww['app/routes/statistics.js']) {
      __cov_PELyvnJ07rp_BOx3i$yeww['app/routes/statistics.js'] = { "path": "app/routes/statistics.js", "s": { "1": 0, "2": 0 }, "b": {}, "f": { "1": 0, "2": 0 }, "fnMap": { "1": { "name": "(anonymous_1)", "line": 5, "loc": { "start": { "line": 5, "column": 7 }, "end": { "line": 5, "column": 10 } } }, "2": { "name": "(anonymous_2)", "line": 9, "loc": { "start": { "line": 9, "column": 12 }, "end": { "line": 9, "column": 15 } } } }, "statementMap": { "1": { "start": { "line": 6, "column": 4 }, "end": { "line": 6, "column": 45 } }, "2": { "start": { "line": 10, "column": 4 }, "end": { "line": 10, "column": 54 } } }, "branchMap": {}, "code": ["import Route from '@ember/routing/route';", "import AuthenticatedRoute from 'prison-rideshare-ui/mixins/authenticated-route';", "", "export default Route.extend(AuthenticatedRoute, {", "  model() {", "    return this.get('store').findAll('ride');", "  },", "", "  afterModel() {", "    return this.get('store').findAll('reimbursement');", "  },", "", "  titleToken: 'Statistics'", "});", ""] };
   }
   __cov_PELyvnJ07rp_BOx3i$yeww = __cov_PELyvnJ07rp_BOx3i$yeww['app/routes/statistics.js'];
   var Route = Ember.Route;
   exports.default = Route.extend(_authenticatedRoute.default, {
      model: function model() {
         __cov_PELyvnJ07rp_BOx3i$yeww.f['1']++;__cov_PELyvnJ07rp_BOx3i$yeww.s['1']++;return this.get('store').findAll('ride');
      },
      afterModel: function afterModel() {
         __cov_PELyvnJ07rp_BOx3i$yeww.f['2']++;__cov_PELyvnJ07rp_BOx3i$yeww.s['2']++;return this.get('store').findAll('reimbursement');
      },
      titleToken: 'Statistics' });
});