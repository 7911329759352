define('prison-rideshare-ui/templates/calendar-error', ['exports'], function (exports) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });

   var __cov_Ep3Vw6Mf8hHuF$9OQR2tiQ = Function('return this')();
   if (!__cov_Ep3Vw6Mf8hHuF$9OQR2tiQ.__coverage__) {
      __cov_Ep3Vw6Mf8hHuF$9OQR2tiQ.__coverage__ = {};
   }
   __cov_Ep3Vw6Mf8hHuF$9OQR2tiQ = __cov_Ep3Vw6Mf8hHuF$9OQR2tiQ.__coverage__;
   if (!__cov_Ep3Vw6Mf8hHuF$9OQR2tiQ['app/templates/calendar-error.hbs']) {
      __cov_Ep3Vw6Mf8hHuF$9OQR2tiQ['app/templates/calendar-error.hbs'] = { "path": "app/templates/calendar-error.hbs", "s": {}, "b": {}, "f": {}, "fnMap": {}, "statementMap": {}, "branchMap": {}, "code": ["export default Ember.HTMLBars.template({\"id\":\"hcdMXZ42\",\"block\":\"{\\\"symbols\\\":[],\\\"statements\\\":[[6,\\\"div\\\"],[9,\\\"class\\\",\\\"error\\\"],[7],[1,[20,[\\\"model\\\",\\\"message\\\"]],false],[8],[0,\\\"\\\\n\\\"]],\\\"hasEval\\\":false}\",\"meta\":{\"moduleName\":\"prison-rideshare-ui/templates/calendar-error.hbs\"}});"] };
   }
   __cov_Ep3Vw6Mf8hHuF$9OQR2tiQ = __cov_Ep3Vw6Mf8hHuF$9OQR2tiQ['app/templates/calendar-error.hbs'];
   exports.default = Ember.HTMLBars.template({ 'id': 'hcdMXZ42', 'block': '{"symbols":[],"statements":[[6,"div"],[9,"class","error"],[7],[1,[20,["model","message"]],false],[8],[0,"\\n"]],"hasEval":false}', 'meta': { 'moduleName': 'prison-rideshare-ui/templates/calendar-error.hbs' } });
});