define('prison-rideshare-ui/services/institutions', ['exports'], function (exports) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });

   var __cov_m2XwLHxqcpUsCDwBtllg5Q = Function('return this')();
   if (!__cov_m2XwLHxqcpUsCDwBtllg5Q.__coverage__) {
      __cov_m2XwLHxqcpUsCDwBtllg5Q.__coverage__ = {};
   }
   __cov_m2XwLHxqcpUsCDwBtllg5Q = __cov_m2XwLHxqcpUsCDwBtllg5Q.__coverage__;
   if (!__cov_m2XwLHxqcpUsCDwBtllg5Q['app/services/institutions.js']) {
      __cov_m2XwLHxqcpUsCDwBtllg5Q['app/services/institutions.js'] = { "path": "app/services/institutions.js", "s": { "1": 0, "2": 0 }, "b": {}, "f": { "1": 0 }, "fnMap": { "1": { "name": "(anonymous_1)", "line": 7, "loc": { "start": { "line": 7, "column": 16 }, "end": { "line": 7, "column": 27 } } } }, "statementMap": { "1": { "start": { "line": 8, "column": 4 }, "end": { "line": 8, "column": 102 } }, "2": { "start": { "line": 8, "column": 73 }, "end": { "line": 8, "column": 100 } } }, "branchMap": {}, "code": ["import { computed } from '@ember/object';", "import Service, { inject as service } from '@ember/service';", "", "export default Service.extend({", "  store: service(),", "", "  all: computed(function() {", "    return this.get('store').findAll('institution').then(institutions => institutions.sortBy('name'));", "  })", "});", ""] };
   }
   __cov_m2XwLHxqcpUsCDwBtllg5Q = __cov_m2XwLHxqcpUsCDwBtllg5Q['app/services/institutions.js'];
   var computed = Ember.computed;
   var Service = Ember.Service;
   var service = Ember.inject.service;
   exports.default = Service.extend({ store: service(), all: computed(function () {
         __cov_m2XwLHxqcpUsCDwBtllg5Q.f['1']++;__cov_m2XwLHxqcpUsCDwBtllg5Q.s['1']++;return this.get('store').findAll('institution').then(function (institutions) {
            __cov_m2XwLHxqcpUsCDwBtllg5Q.s['2']++;return institutions.sortBy('name');
         });
      }) });
});