define('prison-rideshare-ui/templates/components/request-time-chart', ['exports'], function (exports) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });

   var __cov_PKKxOYlVj732ebSFtC6nlw = Function('return this')();
   if (!__cov_PKKxOYlVj732ebSFtC6nlw.__coverage__) {
      __cov_PKKxOYlVj732ebSFtC6nlw.__coverage__ = {};
   }
   __cov_PKKxOYlVj732ebSFtC6nlw = __cov_PKKxOYlVj732ebSFtC6nlw.__coverage__;
   if (!__cov_PKKxOYlVj732ebSFtC6nlw['app/templates/components/request-time-chart.hbs']) {
      __cov_PKKxOYlVj732ebSFtC6nlw['app/templates/components/request-time-chart.hbs'] = { "path": "app/templates/components/request-time-chart.hbs", "s": {}, "b": {}, "f": {}, "fnMap": {}, "statementMap": {}, "branchMap": {}, "code": ["export default Ember.HTMLBars.template({\"id\":\"piRhmOHg\",\"block\":\"{\\\"symbols\\\":[],\\\"statements\\\":[[1,[25,\\\"high-charts\\\",null,[[\\\"content\\\",\\\"chartOptions\\\",\\\"theme\\\"],[[20,[\\\"data\\\"]],[20,[\\\"options\\\"]],[20,[\\\"theme\\\"]]]]],false],[0,\\\"\\\\n\\\"]],\\\"hasEval\\\":false}\",\"meta\":{\"moduleName\":\"prison-rideshare-ui/templates/components/request-time-chart.hbs\"}});"] };
   }
   __cov_PKKxOYlVj732ebSFtC6nlw = __cov_PKKxOYlVj732ebSFtC6nlw['app/templates/components/request-time-chart.hbs'];
   exports.default = Ember.HTMLBars.template({ 'id': 'piRhmOHg', 'block': '{"symbols":[],"statements":[[1,[25,"high-charts",null,[["content","chartOptions","theme"],[[20,["data"]],[20,["options"]],[20,["theme"]]]]],false],[0,"\\n"]],"hasEval":false}', 'meta': { 'moduleName': 'prison-rideshare-ui/templates/components/request-time-chart.hbs' } });
});