define('prison-rideshare-ui/components/calendar-day', ['exports'], function (exports) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });

   var __cov_1FtaH$v0U6eix$RFJ56eiQ = Function('return this')();
   if (!__cov_1FtaH$v0U6eix$RFJ56eiQ.__coverage__) {
      __cov_1FtaH$v0U6eix$RFJ56eiQ.__coverage__ = {};
   }
   __cov_1FtaH$v0U6eix$RFJ56eiQ = __cov_1FtaH$v0U6eix$RFJ56eiQ.__coverage__;
   if (!__cov_1FtaH$v0U6eix$RFJ56eiQ['app/components/calendar-day.js']) {
      __cov_1FtaH$v0U6eix$RFJ56eiQ['app/components/calendar-day.js'] = { "path": "app/components/calendar-day.js", "s": { "1": 0, "2": 0, "3": 0, "4": 0 }, "b": {}, "f": { "1": 0 }, "fnMap": { "1": { "name": "(anonymous_1)", "line": 5, "loc": { "start": { "line": 5, "column": 52 }, "end": { "line": 5, "column": 63 } } } }, "statementMap": { "1": { "start": { "line": 6, "column": 4 }, "end": { "line": 6, "column": 62 } }, "2": { "start": { "line": 7, "column": 4 }, "end": { "line": 7, "column": 36 } }, "3": { "start": { "line": 9, "column": 4 }, "end": { "line": 9, "column": 100 } }, "4": { "start": { "line": 9, "column": 32 }, "end": { "line": 9, "column": 82 } } }, "branchMap": {}, "code": ["import Component from '@ember/component';", "import { computed } from '@ember/object';", "", "export default Component.extend({", "  daySlots: computed('day.id', 'slots.@each.start', function() {", "    const dayDateString = this.get('day.date').toDateString();", "    const slots = this.get('slots');", "", "    return slots.filter(slot => dayDateString === slot.get('start').toDateString()).sortBy('start');", "  })", "});", ""] };
   }
   __cov_1FtaH$v0U6eix$RFJ56eiQ = __cov_1FtaH$v0U6eix$RFJ56eiQ['app/components/calendar-day.js'];
   var Component = Ember.Component;
   var computed = Ember.computed;
   exports.default = Component.extend({ daySlots: computed('day.id', 'slots.@each.start', function () {
         __cov_1FtaH$v0U6eix$RFJ56eiQ.f['1']++;__cov_1FtaH$v0U6eix$RFJ56eiQ.s['1']++;var dayDateString = this.get('day.date').toDateString();__cov_1FtaH$v0U6eix$RFJ56eiQ.s['2']++;var slots = this.get('slots');__cov_1FtaH$v0U6eix$RFJ56eiQ.s['3']++;return slots.filter(function (slot) {
            __cov_1FtaH$v0U6eix$RFJ56eiQ.s['4']++;return dayDateString === slot.get('start').toDateString();
         }).sortBy('start');
      }) });
});