define('prison-rideshare-ui/models/commitment', ['exports', 'ember-data'], function (exports, _emberData) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });

   var __cov_Epz7L2qNqgujnXuk89Cv9g = Function('return this')();
   if (!__cov_Epz7L2qNqgujnXuk89Cv9g.__coverage__) {
      __cov_Epz7L2qNqgujnXuk89Cv9g.__coverage__ = {};
   }
   __cov_Epz7L2qNqgujnXuk89Cv9g = __cov_Epz7L2qNqgujnXuk89Cv9g.__coverage__;
   if (!__cov_Epz7L2qNqgujnXuk89Cv9g['app/models/commitment.js']) {
      __cov_Epz7L2qNqgujnXuk89Cv9g['app/models/commitment.js'] = { "path": "app/models/commitment.js", "s": {}, "b": {}, "f": {}, "fnMap": {}, "statementMap": {}, "branchMap": {}, "code": ["import DS from 'ember-data';", "", "export default DS.Model.extend({", "  slot: DS.belongsTo({ async: false }),", "  person: DS.belongsTo()", "});", ""] };
   }
   __cov_Epz7L2qNqgujnXuk89Cv9g = __cov_Epz7L2qNqgujnXuk89Cv9g['app/models/commitment.js'];
   exports.default = _emberData.default.Model.extend({ slot: _emberData.default.belongsTo({ async: false }), person: _emberData.default.belongsTo() });
});