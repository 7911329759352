define('prison-rideshare-ui/templates/error', ['exports'], function (exports) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });

   var __cov_Oomt1MxtIEbyWTjMNYTvRQ = Function('return this')();
   if (!__cov_Oomt1MxtIEbyWTjMNYTvRQ.__coverage__) {
      __cov_Oomt1MxtIEbyWTjMNYTvRQ.__coverage__ = {};
   }
   __cov_Oomt1MxtIEbyWTjMNYTvRQ = __cov_Oomt1MxtIEbyWTjMNYTvRQ.__coverage__;
   if (!__cov_Oomt1MxtIEbyWTjMNYTvRQ['app/templates/error.hbs']) {
      __cov_Oomt1MxtIEbyWTjMNYTvRQ['app/templates/error.hbs'] = { "path": "app/templates/error.hbs", "s": {}, "b": {}, "f": {}, "fnMap": {}, "statementMap": {}, "branchMap": {}, "code": ["export default Ember.HTMLBars.template({\"id\":\"6G5eqI7Y\",\"block\":\"{\\\"symbols\\\":[],\\\"statements\\\":[[0,\\\"There was an error fetching information. Are you connected to the internet?\\\\n\\\"]],\\\"hasEval\\\":false}\",\"meta\":{\"moduleName\":\"prison-rideshare-ui/templates/error.hbs\"}});"] };
   }
   __cov_Oomt1MxtIEbyWTjMNYTvRQ = __cov_Oomt1MxtIEbyWTjMNYTvRQ['app/templates/error.hbs'];
   exports.default = Ember.HTMLBars.template({ 'id': '6G5eqI7Y', 'block': '{"symbols":[],"statements":[[0,"There was an error fetching information. Are you connected to the internet?\\n"]],"hasEval":false}', 'meta': { 'moduleName': 'prison-rideshare-ui/templates/error.hbs' } });
});