define('prison-rideshare-ui/services/raven', ['exports', 'ember-cli-sentry/services/raven'], function (exports, _raven) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });

   var __cov_iwF3mEBjq4AHbS6aNOvWeA = Function('return this')();
   if (!__cov_iwF3mEBjq4AHbS6aNOvWeA.__coverage__) {
      __cov_iwF3mEBjq4AHbS6aNOvWeA.__coverage__ = {};
   }
   __cov_iwF3mEBjq4AHbS6aNOvWeA = __cov_iwF3mEBjq4AHbS6aNOvWeA.__coverage__;
   if (!__cov_iwF3mEBjq4AHbS6aNOvWeA['app/services/raven.js']) {
      __cov_iwF3mEBjq4AHbS6aNOvWeA['app/services/raven.js'] = { "path": "app/services/raven.js", "s": {}, "b": {}, "f": {}, "fnMap": {}, "statementMap": {}, "branchMap": {}, "code": ["import RavenLogger from 'ember-cli-sentry/services/raven';", "", "export default RavenLogger.extend({", "  unhandledPromiseErrorMessage: ''", "});", ""] };
   }
   __cov_iwF3mEBjq4AHbS6aNOvWeA = __cov_iwF3mEBjq4AHbS6aNOvWeA['app/services/raven.js'];
   exports.default = _raven.default.extend({ unhandledPromiseErrorMessage: '' });
});