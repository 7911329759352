define('prison-rideshare-ui/routes/drivers', ['exports', 'prison-rideshare-ui/mixins/authenticated-route'], function (exports, _authenticatedRoute) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });

   var __cov_LVQzBfVHeKBotuPUNOChEQ = Function('return this')();
   if (!__cov_LVQzBfVHeKBotuPUNOChEQ.__coverage__) {
      __cov_LVQzBfVHeKBotuPUNOChEQ.__coverage__ = {};
   }
   __cov_LVQzBfVHeKBotuPUNOChEQ = __cov_LVQzBfVHeKBotuPUNOChEQ.__coverage__;
   if (!__cov_LVQzBfVHeKBotuPUNOChEQ['app/routes/drivers.js']) {
      __cov_LVQzBfVHeKBotuPUNOChEQ['app/routes/drivers.js'] = { "path": "app/routes/drivers.js", "s": { "1": 0, "2": 0 }, "b": {}, "f": { "1": 0, "2": 0 }, "fnMap": { "1": { "name": "(anonymous_1)", "line": 5, "loc": { "start": { "line": 5, "column": 7 }, "end": { "line": 5, "column": 10 } } }, "2": { "name": "(anonymous_2)", "line": 10, "loc": { "start": { "line": 10, "column": 12 }, "end": { "line": 10, "column": 15 } } } }, "statementMap": { "1": { "start": { "line": 7, "column": 4 }, "end": { "line": 7, "column": 40 } }, "2": { "start": { "line": 12, "column": 4 }, "end": { "line": 12, "column": 38 } } }, "branchMap": {}, "code": ["import Route from '@ember/routing/route';", "import AuthenticatedRoute from 'prison-rideshare-ui/mixins/authenticated-route';", "", "export default Route.extend(AuthenticatedRoute, {", "  model() {", "    // FIXME this formerly used the people service but it wasn’t getting new records…", "    return this.store.findAll('person');", "  },", "", "  afterModel() {", "    // FIXME this is ridiculous, should just be sync relationships perhaps, or have last ride be computed by API", "    return this.store.findAll('ride');", "  },", "", "  titleToken: 'Drivers'", "});", ""] };
   }
   __cov_LVQzBfVHeKBotuPUNOChEQ = __cov_LVQzBfVHeKBotuPUNOChEQ['app/routes/drivers.js'];
   var Route = Ember.Route;
   exports.default = Route.extend(_authenticatedRoute.default, {
      model: function model() {
         __cov_LVQzBfVHeKBotuPUNOChEQ.f['1']++;__cov_LVQzBfVHeKBotuPUNOChEQ.s['1']++;return this.store.findAll('person');
      },
      afterModel: function afterModel() {
         __cov_LVQzBfVHeKBotuPUNOChEQ.f['2']++;__cov_LVQzBfVHeKBotuPUNOChEQ.s['2']++;return this.store.findAll('ride');
      },
      titleToken: 'Drivers' });
});