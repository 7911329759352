define('prison-rideshare-ui/controllers/login', ['exports'], function (exports) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });

   var __cov_OB111C$1VUR59VAHyu3M9A = Function('return this')();
   if (!__cov_OB111C$1VUR59VAHyu3M9A.__coverage__) {
      __cov_OB111C$1VUR59VAHyu3M9A.__coverage__ = {};
   }
   __cov_OB111C$1VUR59VAHyu3M9A = __cov_OB111C$1VUR59VAHyu3M9A.__coverage__;
   if (!__cov_OB111C$1VUR59VAHyu3M9A['app/controllers/login.js']) {
      __cov_OB111C$1VUR59VAHyu3M9A['app/controllers/login.js'] = { "path": "app/controllers/login.js", "s": { "1": 0, "2": 0, "3": 0, "4": 0 }, "b": {}, "f": { "1": 0 }, "fnMap": { "1": { "name": "(anonymous_1)", "line": 8, "loc": { "start": { "line": 8, "column": 9 }, "end": { "line": 8, "column": 17 } } } }, "statementMap": { "1": { "start": { "line": 9, "column": 6 }, "end": { "line": 9, "column": 29 } }, "2": { "start": { "line": 11, "column": 6 }, "end": { "line": 11, "column": 37 } }, "3": { "start": { "line": 13, "column": 6 }, "end": { "line": 19, "column": 8 } }, "4": { "start": { "line": 18, "column": 8 }, "end": { "line": 18, "column": 33 } } }, "branchMap": {}, "code": ["import { inject as service } from '@ember/service';", "import Controller from '@ember/controller';", "", "export default Controller.extend({", "  session: service(),", "", "  actions: {", "    login(event) {", "      event.preventDefault();", "", "      const user = this.get('model');", "", "      this.get('session').authenticate(", "        'authenticator:application',", "        user.get('email'),", "        user.get('password')", "      ).catch(error => {", "        this.set('error', error);", "      })", "    }", "  }", "});", ""] };
   }
   __cov_OB111C$1VUR59VAHyu3M9A = __cov_OB111C$1VUR59VAHyu3M9A['app/controllers/login.js'];
   var service = Ember.inject.service;
   var Controller = Ember.Controller;
   exports.default = Controller.extend({ session: service(), actions: {
         login: function login(event) {
            var _this = this;

            __cov_OB111C$1VUR59VAHyu3M9A.f['1']++;__cov_OB111C$1VUR59VAHyu3M9A.s['1']++;event.preventDefault();__cov_OB111C$1VUR59VAHyu3M9A.s['2']++;var user = this.get('model');__cov_OB111C$1VUR59VAHyu3M9A.s['3']++;this.get('session').authenticate('authenticator:application', user.get('email'), user.get('password')).catch(function (error) {
               __cov_OB111C$1VUR59VAHyu3M9A.s['4']++;_this.set('error', error);
            });
         }
      } });
});