define('prison-rideshare-ui/routes/register', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });

   var __cov_ujVlRGpjVo3lE43XXjYe1Q = Function('return this')();
   if (!__cov_ujVlRGpjVo3lE43XXjYe1Q.__coverage__) {
      __cov_ujVlRGpjVo3lE43XXjYe1Q.__coverage__ = {};
   }
   __cov_ujVlRGpjVo3lE43XXjYe1Q = __cov_ujVlRGpjVo3lE43XXjYe1Q.__coverage__;
   if (!__cov_ujVlRGpjVo3lE43XXjYe1Q['app/routes/register.js']) {
      __cov_ujVlRGpjVo3lE43XXjYe1Q['app/routes/register.js'] = { "path": "app/routes/register.js", "s": { "1": 0 }, "b": {}, "f": { "1": 0 }, "fnMap": { "1": { "name": "(anonymous_1)", "line": 5, "loc": { "start": { "line": 5, "column": 7 }, "end": { "line": 5, "column": 10 } } } }, "statementMap": { "1": { "start": { "line": 6, "column": 4 }, "end": { "line": 6, "column": 43 } } }, "branchMap": {}, "code": ["import Route from '@ember/routing/route';", "import UnauthenticatedRouteMixin from 'ember-simple-auth/mixins/unauthenticated-route-mixin';", "", "export default Route.extend(UnauthenticatedRouteMixin, {", "  model() {", "    return this.store.createRecord('user');", "  },", "", "  titleToken: 'Register'", "});", ""] };
   }
   __cov_ujVlRGpjVo3lE43XXjYe1Q = __cov_ujVlRGpjVo3lE43XXjYe1Q['app/routes/register.js'];
   var Route = Ember.Route;
   exports.default = Route.extend(_unauthenticatedRouteMixin.default, {
      model: function model() {
         __cov_ujVlRGpjVo3lE43XXjYe1Q.f['1']++;__cov_ujVlRGpjVo3lE43XXjYe1Q.s['1']++;return this.store.createRecord('user');
      },
      titleToken: 'Register' });
});