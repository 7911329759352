define('prison-rideshare-ui/models/user', ['exports', 'ember-data'], function (exports, _emberData) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });

   var __cov_pLMDjU7n9NtkpHmmsXRgpg = Function('return this')();
   if (!__cov_pLMDjU7n9NtkpHmmsXRgpg.__coverage__) {
      __cov_pLMDjU7n9NtkpHmmsXRgpg.__coverage__ = {};
   }
   __cov_pLMDjU7n9NtkpHmmsXRgpg = __cov_pLMDjU7n9NtkpHmmsXRgpg.__coverage__;
   if (!__cov_pLMDjU7n9NtkpHmmsXRgpg['app/models/user.js']) {
      __cov_pLMDjU7n9NtkpHmmsXRgpg['app/models/user.js'] = { "path": "app/models/user.js", "s": { "1": 0, "2": 0, "3": 0, "4": 0 }, "b": {}, "f": { "1": 0, "2": 0 }, "fnMap": { "1": { "name": "(anonymous_1)", "line": 14, "loc": { "start": { "line": 14, "column": 39 }, "end": { "line": 14, "column": 50 } } }, "2": { "name": "(anonymous_2)", "line": 18, "loc": { "start": { "line": 18, "column": 55 }, "end": { "line": 18, "column": 66 } } } }, "statementMap": { "1": { "start": { "line": 15, "column": 4 }, "end": { "line": 15, "column": 41 } }, "2": { "start": { "line": 19, "column": 4 }, "end": { "line": 19, "column": 32 } }, "3": { "start": { "line": 20, "column": 4 }, "end": { "line": 20, "column": 75 } }, "4": { "start": { "line": 20, "column": 55 }, "end": { "line": 20, "column": 66 } } }, "branchMap": {}, "code": ["import { computed } from '@ember/object';", "import { inject as service } from '@ember/service';", "import DS from 'ember-data';", "", "export default DS.Model.extend({", "  userSocket: service(),", "", "  email: DS.attr('string'),", "  password: DS.attr('string'),", "  passwordConfirmation: DS.attr('string'),", "", "  admin: DS.attr('boolean'),", "", "  isPresent: computed('presenceCount', function() {", "    return this.get('presenceCount') > 0;", "  }),", "", "  presenceCount: computed('userSocket.present.length', function() {", "    const myId = this.get('id');", "    return this.get('userSocket.present').filter(id => myId === id).length;", "  })", "});", ""] };
   }
   __cov_pLMDjU7n9NtkpHmmsXRgpg = __cov_pLMDjU7n9NtkpHmmsXRgpg['app/models/user.js'];
   var computed = Ember.computed;
   var service = Ember.inject.service;
   exports.default = _emberData.default.Model.extend({ userSocket: service(), email: _emberData.default.attr('string'), password: _emberData.default.attr('string'), passwordConfirmation: _emberData.default.attr('string'), admin: _emberData.default.attr('boolean'), isPresent: computed('presenceCount', function () {
         __cov_pLMDjU7n9NtkpHmmsXRgpg.f['1']++;__cov_pLMDjU7n9NtkpHmmsXRgpg.s['1']++;return this.get('presenceCount') > 0;
      }), presenceCount: computed('userSocket.present.length', function () {
         __cov_pLMDjU7n9NtkpHmmsXRgpg.f['2']++;__cov_pLMDjU7n9NtkpHmmsXRgpg.s['2']++;var myId = this.get('id');__cov_pLMDjU7n9NtkpHmmsXRgpg.s['3']++;return this.get('userSocket.present').filter(function (id) {
            __cov_pLMDjU7n9NtkpHmmsXRgpg.s['4']++;return myId === id;
         }).length;
      }) });
});