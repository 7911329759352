define('prison-rideshare-ui/components/cancellation-form', ['exports', 'prison-rideshare-ui/utils/reason-to-icon'], function (exports, _reasonToIcon) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });

   var __cov_L$Aisaki$nXl80b8JNbNvQ = Function('return this')();
   if (!__cov_L$Aisaki$nXl80b8JNbNvQ.__coverage__) {
      __cov_L$Aisaki$nXl80b8JNbNvQ.__coverage__ = {};
   }
   __cov_L$Aisaki$nXl80b8JNbNvQ = __cov_L$Aisaki$nXl80b8JNbNvQ.__coverage__;
   if (!__cov_L$Aisaki$nXl80b8JNbNvQ['app/components/cancellation-form.js']) {
      __cov_L$Aisaki$nXl80b8JNbNvQ['app/components/cancellation-form.js'] = { "path": "app/components/cancellation-form.js", "s": { "1": 0, "2": 0, "3": 0, "4": 0 }, "b": { "1": [0, 0] }, "f": { "1": 0 }, "fnMap": { "1": { "name": "(anonymous_1)", "line": 11, "loc": { "start": { "line": 11, "column": 20 }, "end": { "line": 11, "column": 32 } } } }, "statementMap": { "1": { "start": { "line": 5, "column": 0 }, "end": { "line": 5, "column": 49 } }, "2": { "start": { "line": 12, "column": 6 }, "end": { "line": 14, "column": 7 } }, "3": { "start": { "line": 13, "column": 8 }, "end": { "line": 13, "column": 50 } }, "4": { "start": { "line": 16, "column": 6 }, "end": { "line": 16, "column": 44 } } }, "branchMap": { "1": { "line": 12, "type": "if", "locations": [{ "start": { "line": 12, "column": 6 }, "end": { "line": 12, "column": 6 } }, { "start": { "line": 12, "column": 6 }, "end": { "line": 12, "column": 6 } }] } }, "code": ["import Component from '@ember/component';", "", "import reasonToIcon from 'prison-rideshare-ui/utils/reason-to-icon';", "", "const reasons = Object.keys(reasonToIcon).sort();", "", "export default Component.extend({", "  reasons,", "", "  actions: {", "    cancelledChanged(cancelled) {", "      if (!cancelled) {", "        this.set('ride.cancellationReason', null);", "      }", "", "      this.set('ride.cancelled', cancelled);", "    }", "  }", "});", ""] };
   }
   __cov_L$Aisaki$nXl80b8JNbNvQ = __cov_L$Aisaki$nXl80b8JNbNvQ['app/components/cancellation-form.js'];
   var Component = Ember.Component;
   __cov_L$Aisaki$nXl80b8JNbNvQ.s['1']++;var reasons = Object.keys(_reasonToIcon.default).sort();exports.default = Component.extend({ reasons: reasons, actions: {
         cancelledChanged: function cancelledChanged(cancelled) {
            __cov_L$Aisaki$nXl80b8JNbNvQ.f['1']++;__cov_L$Aisaki$nXl80b8JNbNvQ.s['2']++;if (!cancelled) {
               __cov_L$Aisaki$nXl80b8JNbNvQ.b['1'][0]++;__cov_L$Aisaki$nXl80b8JNbNvQ.s['3']++;this.set('ride.cancellationReason', null);
            } else {
               __cov_L$Aisaki$nXl80b8JNbNvQ.b['1'][1]++;
            }__cov_L$Aisaki$nXl80b8JNbNvQ.s['4']++;this.set('ride.cancelled', cancelled);
         }
      } });
});