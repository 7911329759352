define('prison-rideshare-ui/components/donation-icon', ['exports'], function (exports) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });

   var __cov_hi9vtAfrFzplvx_op4mP4Q = Function('return this')();
   if (!__cov_hi9vtAfrFzplvx_op4mP4Q.__coverage__) {
      __cov_hi9vtAfrFzplvx_op4mP4Q.__coverage__ = {};
   }
   __cov_hi9vtAfrFzplvx_op4mP4Q = __cov_hi9vtAfrFzplvx_op4mP4Q.__coverage__;
   if (!__cov_hi9vtAfrFzplvx_op4mP4Q['app/components/donation-icon.js']) {
      __cov_hi9vtAfrFzplvx_op4mP4Q['app/components/donation-icon.js'] = { "path": "app/components/donation-icon.js", "s": {}, "b": {}, "f": {}, "fnMap": {}, "statementMap": {}, "branchMap": {}, "code": ["import Component from '@ember/component';", "", "export default Component.extend({", "  tagName: ''", "});", ""] };
   }
   __cov_hi9vtAfrFzplvx_op4mP4Q = __cov_hi9vtAfrFzplvx_op4mP4Q['app/components/donation-icon.js'];
   var Component = Ember.Component;
   exports.default = Component.extend({ tagName: '' });
});