define('prison-rideshare-ui/templates/components/requests-and-reimbursements-chart', ['exports'], function (exports) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });

   var __cov_uE1dYiEuliXzqE1ESsht2w = Function('return this')();
   if (!__cov_uE1dYiEuliXzqE1ESsht2w.__coverage__) {
      __cov_uE1dYiEuliXzqE1ESsht2w.__coverage__ = {};
   }
   __cov_uE1dYiEuliXzqE1ESsht2w = __cov_uE1dYiEuliXzqE1ESsht2w.__coverage__;
   if (!__cov_uE1dYiEuliXzqE1ESsht2w['app/templates/components/requests-and-reimbursements-chart.hbs']) {
      __cov_uE1dYiEuliXzqE1ESsht2w['app/templates/components/requests-and-reimbursements-chart.hbs'] = { "path": "app/templates/components/requests-and-reimbursements-chart.hbs", "s": {}, "b": {}, "f": {}, "fnMap": {}, "statementMap": {}, "branchMap": {}, "code": ["export default Ember.HTMLBars.template({\"id\":\"RP7Q0tUg\",\"block\":\"{\\\"symbols\\\":[],\\\"statements\\\":[[1,[25,\\\"high-charts\\\",null,[[\\\"content\\\",\\\"chartOptions\\\",\\\"theme\\\",\\\"callback\\\"],[[20,[\\\"data\\\"]],[20,[\\\"options\\\"]],[20,[\\\"theme\\\"]],[25,\\\"action\\\",[[19,0,[]],\\\"afterRenderCallback\\\"],null]]]],false],[0,\\\"\\\\n\\\\n\\\"],[4,\\\"if\\\",[[20,[\\\"rendered\\\"]]],null,{\\\"statements\\\":[[4,\\\"ember-wormhole\\\",null,[[\\\"to\\\"],[\\\"grouping-weeks\\\"]],{\\\"statements\\\":[[0,\\\"    \\\"],[1,[25,\\\"paper-button\\\",null,[[\\\"label\\\",\\\"primary\\\",\\\"onClick\\\"],[\\\"Weeks\\\",[25,\\\"eq\\\",[[20,[\\\"grouping\\\"]],\\\"weeks\\\"],null],[25,\\\"action\\\",[[19,0,[]],[25,\\\"mut\\\",[[20,[\\\"grouping\\\"]]],null],\\\"weeks\\\"],null]]]],false],[0,\\\"\\\\n\\\"]],\\\"parameters\\\":[]},null],[4,\\\"ember-wormhole\\\",null,[[\\\"to\\\"],[\\\"grouping-months\\\"]],{\\\"statements\\\":[[0,\\\"    \\\"],[1,[25,\\\"paper-button\\\",null,[[\\\"label\\\",\\\"primary\\\",\\\"onClick\\\"],[\\\"Months\\\",[25,\\\"eq\\\",[[20,[\\\"grouping\\\"]],\\\"months\\\"],null],[25,\\\"action\\\",[[19,0,[]],[25,\\\"mut\\\",[[20,[\\\"grouping\\\"]]],null],\\\"months\\\"],null]]]],false],[0,\\\"\\\\n\\\"]],\\\"parameters\\\":[]},null]],\\\"parameters\\\":[]},null]],\\\"hasEval\\\":false}\",\"meta\":{\"moduleName\":\"prison-rideshare-ui/templates/components/requests-and-reimbursements-chart.hbs\"}});"] };
   }
   __cov_uE1dYiEuliXzqE1ESsht2w = __cov_uE1dYiEuliXzqE1ESsht2w['app/templates/components/requests-and-reimbursements-chart.hbs'];
   exports.default = Ember.HTMLBars.template({ 'id': 'RP7Q0tUg', 'block': '{"symbols":[],"statements":[[1,[25,"high-charts",null,[["content","chartOptions","theme","callback"],[[20,["data"]],[20,["options"]],[20,["theme"]],[25,"action",[[19,0,[]],"afterRenderCallback"],null]]]],false],[0,"\\n\\n"],[4,"if",[[20,["rendered"]]],null,{"statements":[[4,"ember-wormhole",null,[["to"],["grouping-weeks"]],{"statements":[[0,"    "],[1,[25,"paper-button",null,[["label","primary","onClick"],["Weeks",[25,"eq",[[20,["grouping"]],"weeks"],null],[25,"action",[[19,0,[]],[25,"mut",[[20,["grouping"]]],null],"weeks"],null]]]],false],[0,"\\n"]],"parameters":[]},null],[4,"ember-wormhole",null,[["to"],["grouping-months"]],{"statements":[[0,"    "],[1,[25,"paper-button",null,[["label","primary","onClick"],["Months",[25,"eq",[[20,["grouping"]],"months"],null],[25,"action",[[19,0,[]],[25,"mut",[[20,["grouping"]]],null],"months"],null]]]],false],[0,"\\n"]],"parameters":[]},null]],"parameters":[]},null]],"hasEval":false}', 'meta': { 'moduleName': 'prison-rideshare-ui/templates/components/requests-and-reimbursements-chart.hbs' } });
});