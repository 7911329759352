define('prison-rideshare-ui/routes/reports/new', ['exports'], function (exports) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });

   var __cov_7OGAhd2T93Bfun_nKdvXLQ = Function('return this')();
   if (!__cov_7OGAhd2T93Bfun_nKdvXLQ.__coverage__) {
      __cov_7OGAhd2T93Bfun_nKdvXLQ.__coverage__ = {};
   }
   __cov_7OGAhd2T93Bfun_nKdvXLQ = __cov_7OGAhd2T93Bfun_nKdvXLQ.__coverage__;
   if (!__cov_7OGAhd2T93Bfun_nKdvXLQ['app/routes/reports/new.js']) {
      __cov_7OGAhd2T93Bfun_nKdvXLQ['app/routes/reports/new.js'] = { "path": "app/routes/reports/new.js", "s": { "1": 0, "2": 0, "3": 0, "4": 0 }, "b": {}, "f": { "1": 0 }, "fnMap": { "1": { "name": "(anonymous_1)", "line": 4, "loc": { "start": { "line": 4, "column": 7 }, "end": { "line": 4, "column": 10 } } } }, "statementMap": { "1": { "start": { "line": 5, "column": 4 }, "end": { "line": 5, "column": 27 } }, "2": { "start": { "line": 7, "column": 4 }, "end": { "line": 9, "column": 8 } }, "3": { "start": { "line": 7, "column": 52 }, "end": { "line": 9, "column": 6 } }, "4": { "start": { "line": 8, "column": 6 }, "end": { "line": 8, "column": 37 } } }, "branchMap": {}, "code": ["import Route from '@ember/routing/route';", "", "export default Route.extend({", "  model() {", "    const now = new Date();", "", "    return this.store.findAll('ride').then(rides => rides.filterBy('enabled').filterBy('notComplete').rejectBy('isCombined').reject(ride => {", "      return ride.get('start') > now;", "    }));", "  },", "", "  titleToken: 'Ride report'", "});", ""] };
   }
   __cov_7OGAhd2T93Bfun_nKdvXLQ = __cov_7OGAhd2T93Bfun_nKdvXLQ['app/routes/reports/new.js'];
   var Route = Ember.Route;
   exports.default = Route.extend({
      model: function model() {
         __cov_7OGAhd2T93Bfun_nKdvXLQ.f['1']++;__cov_7OGAhd2T93Bfun_nKdvXLQ.s['1']++;var now = new Date();__cov_7OGAhd2T93Bfun_nKdvXLQ.s['2']++;return this.store.findAll('ride').then(function (rides) {
            __cov_7OGAhd2T93Bfun_nKdvXLQ.s['3']++;return rides.filterBy('enabled').filterBy('notComplete').rejectBy('isCombined').reject(function (ride) {
               __cov_7OGAhd2T93Bfun_nKdvXLQ.s['4']++;return ride.get('start') > now;
            });
         });
      },
      titleToken: 'Ride report' });
});