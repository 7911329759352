define('prison-rideshare-ui/services/toasts', ['exports', 'prison-rideshare-ui/config/environment'], function (exports, _environment) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });

   var __cov_wf4lo2x$Z_F3kmckcepNzw = Function('return this')();
   if (!__cov_wf4lo2x$Z_F3kmckcepNzw.__coverage__) {
      __cov_wf4lo2x$Z_F3kmckcepNzw.__coverage__ = {};
   }
   __cov_wf4lo2x$Z_F3kmckcepNzw = __cov_wf4lo2x$Z_F3kmckcepNzw.__coverage__;
   if (!__cov_wf4lo2x$Z_F3kmckcepNzw['app/services/toasts.js']) {
      __cov_wf4lo2x$Z_F3kmckcepNzw['app/services/toasts.js'] = { "path": "app/services/toasts.js", "s": { "1": 0 }, "b": {}, "f": { "1": 0 }, "fnMap": { "1": { "name": "(anonymous_1)", "line": 8, "loc": { "start": { "line": 8, "column": 6 }, "end": { "line": 8, "column": 16 } } } }, "statementMap": { "1": { "start": { "line": 9, "column": 4 }, "end": { "line": 12, "column": 7 } } }, "branchMap": {}, "code": ["import Service from '@ember/service';", "import { inject as service } from '@ember/service';", "import config from 'prison-rideshare-ui/config/environment';", "", "export default Service.extend({", "  paperToaster: service(),", "", "  show(message) {", "    this.get('paperToaster').show(message, {", "      duration: config.toastDuration,", "      position: 'top right'", "    });", "  }", "});", ""] };
   }
   __cov_wf4lo2x$Z_F3kmckcepNzw = __cov_wf4lo2x$Z_F3kmckcepNzw['app/services/toasts.js'];
   var Service = Ember.Service;
   var service = Ember.inject.service;
   exports.default = Service.extend({ paperToaster: service(), show: function show(message) {
         __cov_wf4lo2x$Z_F3kmckcepNzw.f['1']++;__cov_wf4lo2x$Z_F3kmckcepNzw.s['1']++;this.get('paperToaster').show(message, { duration: _environment.default.toastDuration, position: 'top right' });
      }
   });
});