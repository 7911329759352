define('prison-rideshare-ui/models/debt', ['exports', 'ember-data', 'prison-rideshare-ui/utils/dollars', 'ember-cpm/macros/sum'], function (exports, _emberData, _dollars, _sum) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });

   var __cov_hxwABEPeMMrERQEKywsvVA = Function('return this')();
   if (!__cov_hxwABEPeMMrERQEKywsvVA.__coverage__) {
      __cov_hxwABEPeMMrERQEKywsvVA.__coverage__ = {};
   }
   __cov_hxwABEPeMMrERQEKywsvVA = __cov_hxwABEPeMMrERQEKywsvVA.__coverage__;
   if (!__cov_hxwABEPeMMrERQEKywsvVA['app/models/debt.js']) {
      __cov_hxwABEPeMMrERQEKywsvVA['app/models/debt.js'] = { "path": "app/models/debt.js", "s": { "1": 0, "2": 0 }, "b": {}, "f": { "1": 0, "2": 0 }, "fnMap": { "1": { "name": "(anonymous_1)", "line": 17, "loc": { "start": { "line": 17, "column": 66 }, "end": { "line": 17, "column": 77 } } }, "2": { "name": "(anonymous_2)", "line": 24, "loc": { "start": { "line": 24, "column": 67 }, "end": { "line": 24, "column": 78 } } } }, "statementMap": { "1": { "start": { "line": 18, "column": 4 }, "end": { "line": 18, "column": 74 } }, "2": { "start": { "line": 25, "column": 4 }, "end": { "line": 25, "column": 76 } } }, "branchMap": {}, "code": ["import { computed } from '@ember/object';", "import { sort, mapBy } from '@ember/object/computed';", "import DS from 'ember-data';", "", "import dollars from 'prison-rideshare-ui/utils/dollars';", "", "import sum from 'ember-cpm/macros/sum';", "", "export default DS.Model.extend({", "  person: DS.belongsTo(),", "", "  rides: DS.hasMany(),", "", "  descendingRides: sort('rides', 'descendingRideSort'),", "  descendingRideSort: Object.freeze(['start:desc']),", "", "  ridesWithFoodExpenses: computed('rides.@each.driver', 'person', function() {", "    return this.get('rides').filterBy('driver.id', this.get('person.id'));", "  }),", "  rideFoodExpenses: mapBy('ridesWithFoodExpenses', 'outstandingFoodExpenses'),", "  foodExpenses: sum('rideFoodExpenses'),", "  foodExpensesDollars: dollars('foodExpenses'),", "", "  ridesWithCarExpenses: computed('rides.@each.carOwner', 'person', function() {", "    return this.get('rides').filterBy('carOwner.id', this.get('person.id'));", "  }),", "  rideCarExpenses: mapBy('ridesWithCarExpenses', 'outstandingCarExpenses'),", "  carExpenses: sum('rideCarExpenses'),", "  carExpensesDollars: dollars('carExpenses'),", "", "  totalExpenses: sum('foodExpenses', 'carExpenses'),", "  totalExpensesDollars: dollars('totalExpenses')", "});", ""] };
   }
   __cov_hxwABEPeMMrERQEKywsvVA = __cov_hxwABEPeMMrERQEKywsvVA['app/models/debt.js'];
   var computed = Ember.computed;
   var sort = Ember.computed.sort;
   var mapBy = Ember.computed.mapBy;
   exports.default = _emberData.default.Model.extend({ person: _emberData.default.belongsTo(), rides: _emberData.default.hasMany(), descendingRides: sort('rides', 'descendingRideSort'), descendingRideSort: Object.freeze(['start:desc']), ridesWithFoodExpenses: computed('rides.@each.driver', 'person', function () {
         __cov_hxwABEPeMMrERQEKywsvVA.f['1']++;__cov_hxwABEPeMMrERQEKywsvVA.s['1']++;return this.get('rides').filterBy('driver.id', this.get('person.id'));
      }), rideFoodExpenses: mapBy('ridesWithFoodExpenses', 'outstandingFoodExpenses'), foodExpenses: (0, _sum.default)('rideFoodExpenses'), foodExpensesDollars: (0, _dollars.default)('foodExpenses'), ridesWithCarExpenses: computed('rides.@each.carOwner', 'person', function () {
         __cov_hxwABEPeMMrERQEKywsvVA.f['2']++;__cov_hxwABEPeMMrERQEKywsvVA.s['2']++;return this.get('rides').filterBy('carOwner.id', this.get('person.id'));
      }), rideCarExpenses: mapBy('ridesWithCarExpenses', 'outstandingCarExpenses'), carExpenses: (0, _sum.default)('rideCarExpenses'), carExpensesDollars: (0, _dollars.default)('carExpenses'), totalExpenses: (0, _sum.default)('foodExpenses', 'carExpenses'), totalExpensesDollars: (0, _dollars.default)('totalExpenses') });
});