define('prison-rideshare-ui/resolver', ['exports', 'ember-resolver'], function (exports, _emberResolver) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });

   var __cov_teehtYCCZQQgx6YrQ6jTnQ = Function('return this')();
   if (!__cov_teehtYCCZQQgx6YrQ6jTnQ.__coverage__) {
      __cov_teehtYCCZQQgx6YrQ6jTnQ.__coverage__ = {};
   }
   __cov_teehtYCCZQQgx6YrQ6jTnQ = __cov_teehtYCCZQQgx6YrQ6jTnQ.__coverage__;
   if (!__cov_teehtYCCZQQgx6YrQ6jTnQ['app/resolver.js']) {
      __cov_teehtYCCZQQgx6YrQ6jTnQ['app/resolver.js'] = { "path": "app/resolver.js", "s": {}, "b": {}, "f": {}, "fnMap": {}, "statementMap": {}, "branchMap": {}, "code": ["import Resolver from 'ember-resolver';", "", "export default Resolver;", ""] };
   }
   __cov_teehtYCCZQQgx6YrQ6jTnQ = __cov_teehtYCCZQQgx6YrQ6jTnQ['app/resolver.js'];
   exports.default = _emberResolver.default;
});