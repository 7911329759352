define('prison-rideshare-ui/controllers/users', ['exports'], function (exports) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });

   var __cov_XcoTsBNLXYJL8BybNDeSJA = Function('return this')();
   if (!__cov_XcoTsBNLXYJL8BybNDeSJA.__coverage__) {
      __cov_XcoTsBNLXYJL8BybNDeSJA.__coverage__ = {};
   }
   __cov_XcoTsBNLXYJL8BybNDeSJA = __cov_XcoTsBNLXYJL8BybNDeSJA.__coverage__;
   if (!__cov_XcoTsBNLXYJL8BybNDeSJA['app/controllers/users.js']) {
      __cov_XcoTsBNLXYJL8BybNDeSJA['app/controllers/users.js'] = { "path": "app/controllers/users.js", "s": { "1": 0, "2": 0 }, "b": {}, "f": { "1": 0 }, "fnMap": { "1": { "name": "(anonymous_1)", "line": 8, "loc": { "start": { "line": 8, "column": 19 }, "end": { "line": 8, "column": 33 } } } }, "statementMap": { "1": { "start": { "line": 9, "column": 6 }, "end": { "line": 9, "column": 31 } }, "2": { "start": { "line": 10, "column": 6 }, "end": { "line": 10, "column": 18 } } }, "branchMap": {}, "code": ["import { inject as service } from '@ember/service';", "import Controller from '@ember/controller';", "", "export default Controller.extend({", "  session: service(),", "", "  actions: {", "    updateUserAdmin(user, admin) {", "      user.set('admin', admin);", "      user.save();", "    }", "  }", "});", ""] };
   }
   __cov_XcoTsBNLXYJL8BybNDeSJA = __cov_XcoTsBNLXYJL8BybNDeSJA['app/controllers/users.js'];
   var service = Ember.inject.service;
   var Controller = Ember.Controller;
   exports.default = Controller.extend({ session: service(), actions: {
         updateUserAdmin: function updateUserAdmin(user, admin) {
            __cov_XcoTsBNLXYJL8BybNDeSJA.f['1']++;__cov_XcoTsBNLXYJL8BybNDeSJA.s['1']++;user.set('admin', admin);__cov_XcoTsBNLXYJL8BybNDeSJA.s['2']++;user.save();
         }
      } });
});