define('prison-rideshare-ui/routes/login', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });

   var __cov_4jG58FSqcHBMKjnsR3Ejkg = Function('return this')();
   if (!__cov_4jG58FSqcHBMKjnsR3Ejkg.__coverage__) {
      __cov_4jG58FSqcHBMKjnsR3Ejkg.__coverage__ = {};
   }
   __cov_4jG58FSqcHBMKjnsR3Ejkg = __cov_4jG58FSqcHBMKjnsR3Ejkg.__coverage__;
   if (!__cov_4jG58FSqcHBMKjnsR3Ejkg['app/routes/login.js']) {
      __cov_4jG58FSqcHBMKjnsR3Ejkg['app/routes/login.js'] = { "path": "app/routes/login.js", "s": { "1": 0, "2": 0, "3": 0, "4": 0, "5": 0 }, "b": { "1": [0, 0] }, "f": { "1": 0 }, "fnMap": { "1": { "name": "(anonymous_1)", "line": 6, "loc": { "start": { "line": 6, "column": 7 }, "end": { "line": 6, "column": 10 } } } }, "statementMap": { "1": { "start": { "line": 7, "column": 4 }, "end": { "line": 7, "column": 20 } }, "2": { "start": { "line": 9, "column": 4 }, "end": { "line": 12, "column": 5 } }, "3": { "start": { "line": 10, "column": 6 }, "end": { "line": 10, "column": 36 } }, "4": { "start": { "line": 11, "column": 6 }, "end": { "line": 11, "column": 33 } }, "5": { "start": { "line": 14, "column": 4 }, "end": { "line": 14, "column": 36 } } }, "branchMap": { "1": { "line": 9, "type": "if", "locations": [{ "start": { "line": 9, "column": 4 }, "end": { "line": 9, "column": 4 } }, { "start": { "line": 9, "column": 4 }, "end": { "line": 9, "column": 4 } }] } }, "code": ["import EmberObject from '@ember/object';", "import Route from '@ember/routing/route';", "import UnauthenticatedRouteMixin from 'ember-simple-auth/mixins/unauthenticated-route-mixin';", "", "export default Route.extend(UnauthenticatedRouteMixin, {", "  model() {", "    const user = {};", "", "    if (window.location.hostname.indexOf('sandbox') > -1) {", "      user.email = 'jorts@jants.ca';", "      user.password = 'password';", "    }", "", "    return EmberObject.create(user);", "  },", "", "  titleToken: 'Log in'", "});", ""] };
   }
   __cov_4jG58FSqcHBMKjnsR3Ejkg = __cov_4jG58FSqcHBMKjnsR3Ejkg['app/routes/login.js'];
   var EmberObject = Ember.Object;
   var Route = Ember.Route;
   exports.default = Route.extend(_unauthenticatedRouteMixin.default, {
      model: function model() {
         __cov_4jG58FSqcHBMKjnsR3Ejkg.f['1']++;__cov_4jG58FSqcHBMKjnsR3Ejkg.s['1']++;var user = {};__cov_4jG58FSqcHBMKjnsR3Ejkg.s['2']++;if (window.location.hostname.indexOf('sandbox') > -1) {
            __cov_4jG58FSqcHBMKjnsR3Ejkg.b['1'][0]++;__cov_4jG58FSqcHBMKjnsR3Ejkg.s['3']++;user.email = 'jorts@jants.ca';__cov_4jG58FSqcHBMKjnsR3Ejkg.s['4']++;user.password = 'password';
         } else {
            __cov_4jG58FSqcHBMKjnsR3Ejkg.b['1'][1]++;
         }__cov_4jG58FSqcHBMKjnsR3Ejkg.s['5']++;return EmberObject.create(user);
      },
      titleToken: 'Log in' });
});