define('prison-rideshare-ui/routes/admin-calendar', ['exports', 'moment', 'prison-rideshare-ui/mixins/authenticated-route'], function (exports, _moment, _authenticatedRoute) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });

   var __cov_NULr3L3ZJ$NE_9Mz7hKYaw = Function('return this')();
   if (!__cov_NULr3L3ZJ$NE_9Mz7hKYaw.__coverage__) {
      __cov_NULr3L3ZJ$NE_9Mz7hKYaw.__coverage__ = {};
   }
   __cov_NULr3L3ZJ$NE_9Mz7hKYaw = __cov_NULr3L3ZJ$NE_9Mz7hKYaw.__coverage__;
   if (!__cov_NULr3L3ZJ$NE_9Mz7hKYaw['app/routes/admin-calendar.js']) {
      __cov_NULr3L3ZJ$NE_9Mz7hKYaw['app/routes/admin-calendar.js'] = { "path": "app/routes/admin-calendar.js", "s": { "1": 0, "2": 0, "3": 0 }, "b": {}, "f": { "1": 0 }, "fnMap": { "1": { "name": "(anonymous_1)", "line": 7, "loc": { "start": { "line": 7, "column": 7 }, "end": { "line": 7, "column": 19 } } } }, "statementMap": { "1": { "start": { "line": 8, "column": 4 }, "end": { "line": 12, "column": 7 } }, "2": { "start": { "line": 10, "column": 23 }, "end": { "line": 10, "column": 98 } }, "3": { "start": { "line": 10, "column": 44 }, "end": { "line": 10, "column": 97 } } }, "branchMap": {}, "code": ["import Route from '@ember/routing/route';", "import RSVP from 'rsvp';", "import moment from 'moment';", "import AuthenticatedRoute from 'prison-rideshare-ui/mixins/authenticated-route';", "", "export default Route.extend(AuthenticatedRoute, {", "  model({ month }) {", "    return RSVP.hash({", "      slots: this.store.findAll('slot')", "        .then(slots => slots.filter(slot => moment(slot.get('start')).format('YYYY-MM') === month)),", "      month", "    });", "  }", "});", ""] };
   }
   __cov_NULr3L3ZJ$NE_9Mz7hKYaw = __cov_NULr3L3ZJ$NE_9Mz7hKYaw['app/routes/admin-calendar.js'];
   var Route = Ember.Route;
   var RSVP = Ember.RSVP;
   exports.default = Route.extend(_authenticatedRoute.default, {
      model: function model(_ref) {
         var month = _ref.month;
         __cov_NULr3L3ZJ$NE_9Mz7hKYaw.f['1']++;__cov_NULr3L3ZJ$NE_9Mz7hKYaw.s['1']++;return RSVP.hash({ slots: this.store.findAll('slot').then(function (slots) {
               __cov_NULr3L3ZJ$NE_9Mz7hKYaw.s['2']++;return slots.filter(function (slot) {
                  __cov_NULr3L3ZJ$NE_9Mz7hKYaw.s['3']++;return (0, _moment.default)(slot.get('start')).format('YYYY-MM') === month;
               });
            }), month: month });
      }
   });
});