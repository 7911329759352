define('prison-rideshare-ui/templates/components/linked-contact', ['exports'], function (exports) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });

   var __cov_YbgQPavoEvvH_DKpk0oelA = Function('return this')();
   if (!__cov_YbgQPavoEvvH_DKpk0oelA.__coverage__) {
      __cov_YbgQPavoEvvH_DKpk0oelA.__coverage__ = {};
   }
   __cov_YbgQPavoEvvH_DKpk0oelA = __cov_YbgQPavoEvvH_DKpk0oelA.__coverage__;
   if (!__cov_YbgQPavoEvvH_DKpk0oelA['app/templates/components/linked-contact.hbs']) {
      __cov_YbgQPavoEvvH_DKpk0oelA['app/templates/components/linked-contact.hbs'] = { "path": "app/templates/components/linked-contact.hbs", "s": {}, "b": {}, "f": {}, "fnMap": {}, "statementMap": {}, "branchMap": {}, "code": ["export default Ember.HTMLBars.template({\"id\":\"b6/nnH8k\",\"block\":\"{\\\"symbols\\\":[],\\\"statements\\\":[[1,[18,\\\"linkedContact\\\"],false],[0,\\\"\\\\n\\\"]],\\\"hasEval\\\":false}\",\"meta\":{\"moduleName\":\"prison-rideshare-ui/templates/components/linked-contact.hbs\"}});"] };
   }
   __cov_YbgQPavoEvvH_DKpk0oelA = __cov_YbgQPavoEvvH_DKpk0oelA['app/templates/components/linked-contact.hbs'];
   exports.default = Ember.HTMLBars.template({ 'id': 'b6/nnH8k', 'block': '{"symbols":[],"statements":[[1,[18,"linkedContact"],false],[0,"\\n"]],"hasEval":false}', 'meta': { 'moduleName': 'prison-rideshare-ui/templates/components/linked-contact.hbs' } });
});