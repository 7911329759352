define('prison-rideshare-ui/services/people', ['exports', 'ember-data'], function (exports, _emberData) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });

   var __cov_$VVRNcUJ2y12_EO9iEvn5g = Function('return this')();
   if (!__cov_$VVRNcUJ2y12_EO9iEvn5g.__coverage__) {
      __cov_$VVRNcUJ2y12_EO9iEvn5g.__coverage__ = {};
   }
   __cov_$VVRNcUJ2y12_EO9iEvn5g = __cov_$VVRNcUJ2y12_EO9iEvn5g.__coverage__;
   if (!__cov_$VVRNcUJ2y12_EO9iEvn5g['app/services/people.js']) {
      __cov_$VVRNcUJ2y12_EO9iEvn5g['app/services/people.js'] = { "path": "app/services/people.js", "s": { "1": 0, "2": 0, "3": 0 }, "b": {}, "f": { "1": 0, "2": 0 }, "fnMap": { "1": { "name": "(anonymous_1)", "line": 9, "loc": { "start": { "line": 9, "column": 20 }, "end": { "line": 9, "column": 31 } } }, "2": { "name": "(anonymous_2)", "line": 13, "loc": { "start": { "line": 13, "column": 38 }, "end": { "line": 13, "column": 49 } } } }, "statementMap": { "1": { "start": { "line": 10, "column": 4 }, "end": { "line": 10, "column": 47 } }, "2": { "start": { "line": 14, "column": 4 }, "end": { "line": 16, "column": 7 } }, "3": { "start": { "line": 15, "column": 50 }, "end": { "line": 15, "column": 71 } } }, "branchMap": {}, "code": ["import { computed } from '@ember/object';", "import { filterBy } from '@ember/object/computed';", "import Service, { inject as service } from '@ember/service';", "import DS from 'ember-data';", "", "export default Service.extend({", "  store: service(),", "", "  findAll: computed(function() {", "    return this.get('store').findAll('person');", "  }),", "", "  all: computed('findAll.@each.name', function() {", "    return DS.PromiseArray.create({", "      promise: this.get('findAll').then(people => people.sortBy('name'))", "    });", "  }),", "", "  active: filterBy('all', 'active')", "});", ""] };
   }
   __cov_$VVRNcUJ2y12_EO9iEvn5g = __cov_$VVRNcUJ2y12_EO9iEvn5g['app/services/people.js'];
   var computed = Ember.computed;
   var filterBy = Ember.computed.filterBy;
   var Service = Ember.Service;
   var service = Ember.inject.service;
   exports.default = Service.extend({ store: service(), findAll: computed(function () {
         __cov_$VVRNcUJ2y12_EO9iEvn5g.f['1']++;__cov_$VVRNcUJ2y12_EO9iEvn5g.s['1']++;return this.get('store').findAll('person');
      }), all: computed('findAll.@each.name', function () {
         __cov_$VVRNcUJ2y12_EO9iEvn5g.f['2']++;__cov_$VVRNcUJ2y12_EO9iEvn5g.s['2']++;return _emberData.default.PromiseArray.create({ promise: this.get('findAll').then(function (people) {
               __cov_$VVRNcUJ2y12_EO9iEvn5g.s['3']++;return people.sortBy('name');
            }) });
      }), active: filterBy('all', 'active') });
});