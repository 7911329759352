define('prison-rideshare-ui/services/sidebar', ['exports'], function (exports) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });

   var __cov_Ex5dbEGLeVV$YJkYbQD5oQ = Function('return this')();
   if (!__cov_Ex5dbEGLeVV$YJkYbQD5oQ.__coverage__) {
      __cov_Ex5dbEGLeVV$YJkYbQD5oQ.__coverage__ = {};
   }
   __cov_Ex5dbEGLeVV$YJkYbQD5oQ = __cov_Ex5dbEGLeVV$YJkYbQD5oQ.__coverage__;
   if (!__cov_Ex5dbEGLeVV$YJkYbQD5oQ['app/services/sidebar.js']) {
      __cov_Ex5dbEGLeVV$YJkYbQD5oQ['app/services/sidebar.js'] = { "path": "app/services/sidebar.js", "s": {}, "b": {}, "f": {}, "fnMap": {}, "statementMap": {}, "branchMap": {}, "code": ["import Service from '@ember/service';", "", "export default Service.extend({", "  open: false", "});", ""] };
   }
   __cov_Ex5dbEGLeVV$YJkYbQD5oQ = __cov_Ex5dbEGLeVV$YJkYbQD5oQ['app/services/sidebar.js'];
   var Service = Ember.Service;
   exports.default = Service.extend({ open: false });
});