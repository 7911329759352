define('prison-rideshare-ui/components/linked-contact', ['exports'], function (exports) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });

   var __cov_QbA1oCifSwDClkZvP8b5wQ = Function('return this')();
   if (!__cov_QbA1oCifSwDClkZvP8b5wQ.__coverage__) {
      __cov_QbA1oCifSwDClkZvP8b5wQ.__coverage__ = {};
   }
   __cov_QbA1oCifSwDClkZvP8b5wQ = __cov_QbA1oCifSwDClkZvP8b5wQ.__coverage__;
   if (!__cov_QbA1oCifSwDClkZvP8b5wQ['app/components/linked-contact.js']) {
      __cov_QbA1oCifSwDClkZvP8b5wQ['app/components/linked-contact.js'] = { "path": "app/components/linked-contact.js", "s": { "1": 0, "2": 0, "3": 0, "4": 0, "5": 0, "6": 0 }, "b": { "1": [0, 0] }, "f": { "1": 0, "2": 0 }, "fnMap": { "1": { "name": "(anonymous_1)", "line": 9, "loc": { "start": { "line": 9, "column": 37 }, "end": { "line": 9, "column": 48 } } }, "2": { "name": "(anonymous_2)", "line": 14, "loc": { "start": { "line": 14, "column": 52 }, "end": { "line": 14, "column": 69 } } } }, "statementMap": { "1": { "start": { "line": 10, "column": 4 }, "end": { "line": 10, "column": 81 } }, "2": { "start": { "line": 11, "column": 4 }, "end": { "line": 11, "column": 40 } }, "3": { "start": { "line": 13, "column": 4 }, "end": { "line": 18, "column": 5 } }, "4": { "start": { "line": 14, "column": 6 }, "end": { "line": 17, "column": 10 } }, "5": { "start": { "line": 15, "column": 8 }, "end": { "line": 15, "column": 78 } }, "6": { "start": { "line": 16, "column": 8 }, "end": { "line": 16, "column": 69 } } }, "branchMap": { "1": { "line": 13, "type": "if", "locations": [{ "start": { "line": 13, "column": 4 }, "end": { "line": 13, "column": 4 } }, { "start": { "line": 13, "column": 4 }, "end": { "line": 13, "column": 4 } }] } }, "code": ["import Ember from \"ember\";", "import Component from '@ember/component';", "import { computed } from '@ember/object';", "import { htmlSafe } from '@ember/string';", "", "export default Component.extend({", "  tagName: 'span',", "", "  linkedContact: computed('contact', function() {", "    const phonePattern = /(\\([0-9]{3}\\)\\s?|[0-9]{3}-?\\s?)[0-9]{3}-?\\s?[0-9]{4}/g;", "    const contact = this.get('contact');", "", "    if (contact) {", "      return htmlSafe(contact.replace(phonePattern, function(number) {", "        const escapedNumber = Ember.Handlebars.Utils.escapeExpression(number);", "        return `<a href='tel:${escapedNumber}'>${escapedNumber}</a>`;", "      }));", "    }", "  })", "});", ""] };
   }
   __cov_QbA1oCifSwDClkZvP8b5wQ = __cov_QbA1oCifSwDClkZvP8b5wQ['app/components/linked-contact.js'];
   var Component = Ember.Component;
   var computed = Ember.computed;
   var htmlSafe = Ember.String.htmlSafe;
   exports.default = Component.extend({ tagName: 'span', linkedContact: computed('contact', function () {
         __cov_QbA1oCifSwDClkZvP8b5wQ.f['1']++;__cov_QbA1oCifSwDClkZvP8b5wQ.s['1']++;var phonePattern = /(\([0-9]{3}\)\s?|[0-9]{3}-?\s?)[0-9]{3}-?\s?[0-9]{4}/g;__cov_QbA1oCifSwDClkZvP8b5wQ.s['2']++;var contact = this.get('contact');__cov_QbA1oCifSwDClkZvP8b5wQ.s['3']++;if (contact) {
            __cov_QbA1oCifSwDClkZvP8b5wQ.b['1'][0]++;__cov_QbA1oCifSwDClkZvP8b5wQ.s['4']++;return htmlSafe(contact.replace(phonePattern, function (number) {
               __cov_QbA1oCifSwDClkZvP8b5wQ.f['2']++;__cov_QbA1oCifSwDClkZvP8b5wQ.s['5']++;var escapedNumber = Ember.Handlebars.Utils.escapeExpression(number);__cov_QbA1oCifSwDClkZvP8b5wQ.s['6']++;return '<a href=\'tel:' + escapedNumber + '\'>' + escapedNumber + '</a>';
            }));
         } else {
            __cov_QbA1oCifSwDClkZvP8b5wQ.b['1'][1]++;
         }
      }) });
});