define('prison-rideshare-ui/components/person-badge', ['exports'], function (exports) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });

   var __cov_CTndPxl9pn08AgomKa_YNw = Function('return this')();
   if (!__cov_CTndPxl9pn08AgomKa_YNw.__coverage__) {
      __cov_CTndPxl9pn08AgomKa_YNw.__coverage__ = {};
   }
   __cov_CTndPxl9pn08AgomKa_YNw = __cov_CTndPxl9pn08AgomKa_YNw.__coverage__;
   if (!__cov_CTndPxl9pn08AgomKa_YNw['app/components/person-badge.js']) {
      __cov_CTndPxl9pn08AgomKa_YNw['app/components/person-badge.js'] = { "path": "app/components/person-badge.js", "s": { "1": 0 }, "b": {}, "f": { "1": 0 }, "fnMap": { "1": { "name": "(anonymous_1)", "line": 9, "loc": { "start": { "line": 9, "column": 17 }, "end": { "line": 9, "column": 20 } } } }, "statementMap": { "1": { "start": { "line": 10, "column": 6 }, "end": { "line": 10, "column": 41 } } }, "branchMap": {}, "code": ["import Component from '@ember/component';", "", "export default Component.extend({", "  classNames: ['person-badge'],", "", "  showContact: false,", "", "  actions: {", "    toggleContact() {", "      this.toggleProperty('showContact');", "    }", "  }", "});", ""] };
   }
   __cov_CTndPxl9pn08AgomKa_YNw = __cov_CTndPxl9pn08AgomKa_YNw['app/components/person-badge.js'];
   var Component = Ember.Component;
   exports.default = Component.extend({ classNames: ['person-badge'], showContact: false, actions: {
         toggleContact: function toggleContact() {
            __cov_CTndPxl9pn08AgomKa_YNw.f['1']++;__cov_CTndPxl9pn08AgomKa_YNw.s['1']++;this.toggleProperty('showContact');
         }
      } });
});