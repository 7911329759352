define('prison-rideshare-ui/templates/rides/index', ['exports'], function (exports) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });

   var __cov_CQiCdFejrgNxK$mRWkCblQ = Function('return this')();
   if (!__cov_CQiCdFejrgNxK$mRWkCblQ.__coverage__) {
      __cov_CQiCdFejrgNxK$mRWkCblQ.__coverage__ = {};
   }
   __cov_CQiCdFejrgNxK$mRWkCblQ = __cov_CQiCdFejrgNxK$mRWkCblQ.__coverage__;
   if (!__cov_CQiCdFejrgNxK$mRWkCblQ['app/templates/rides/index.hbs']) {
      __cov_CQiCdFejrgNxK$mRWkCblQ['app/templates/rides/index.hbs'] = { "path": "app/templates/rides/index.hbs", "s": {}, "b": {}, "f": {}, "fnMap": {}, "statementMap": {}, "branchMap": {}, "code": ["export default Ember.HTMLBars.template({\"id\":\"ZHR8UXL9\",\"block\":\"{\\\"symbols\\\":[],\\\"statements\\\":[[4,\\\"link-to\\\",[\\\"rides.new\\\"],null,{\\\"statements\\\":[[0,\\\"New ride\\\"]],\\\"parameters\\\":[]},null],[0,\\\"\\\\n\\\"]],\\\"hasEval\\\":false}\",\"meta\":{\"moduleName\":\"prison-rideshare-ui/templates/rides/index.hbs\"}});"] };
   }
   __cov_CQiCdFejrgNxK$mRWkCblQ = __cov_CQiCdFejrgNxK$mRWkCblQ['app/templates/rides/index.hbs'];
   exports.default = Ember.HTMLBars.template({ 'id': 'ZHR8UXL9', 'block': '{"symbols":[],"statements":[[4,"link-to",["rides.new"],null,{"statements":[[0,"New ride"]],"parameters":[]},null],[0,"\\n"]],"hasEval":false}', 'meta': { 'moduleName': 'prison-rideshare-ui/templates/rides/index.hbs' } });
});