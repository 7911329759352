define('prison-rideshare-ui/utils/dollars', ['exports'], function (exports) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });

   exports.default = function (property) {
      __cov_dC1sCU00j$yMG85gmYfPxQ.f['1']++;__cov_dC1sCU00j$yMG85gmYfPxQ.s['2']++;return computed(property, {
         get: function get() {
            __cov_dC1sCU00j$yMG85gmYfPxQ.f['2']++;__cov_dC1sCU00j$yMG85gmYfPxQ.s['3']++;return this.get(property) / 100;
         },
         set: function set(key, value) {
            __cov_dC1sCU00j$yMG85gmYfPxQ.f['3']++;__cov_dC1sCU00j$yMG85gmYfPxQ.s['4']++;this.set(property, Math.floor(value * 100));__cov_dC1sCU00j$yMG85gmYfPxQ.s['5']++;return value;
         }
      });
   };

   var __cov_dC1sCU00j$yMG85gmYfPxQ = Function('return this')();
   if (!__cov_dC1sCU00j$yMG85gmYfPxQ.__coverage__) {
      __cov_dC1sCU00j$yMG85gmYfPxQ.__coverage__ = {};
   }
   __cov_dC1sCU00j$yMG85gmYfPxQ = __cov_dC1sCU00j$yMG85gmYfPxQ.__coverage__;
   if (!__cov_dC1sCU00j$yMG85gmYfPxQ['app/utils/dollars.js']) {
      __cov_dC1sCU00j$yMG85gmYfPxQ['app/utils/dollars.js'] = { "path": "app/utils/dollars.js", "s": { "1": 1, "2": 0, "3": 0, "4": 0, "5": 0 }, "b": {}, "f": { "1": 0, "2": 0, "3": 0 }, "fnMap": { "1": { "name": "(anonymous_1)", "line": 3, "loc": { "start": { "line": 3, "column": 15 }, "end": { "line": 3, "column": 34 } } }, "2": { "name": "(anonymous_2)", "line": 5, "loc": { "start": { "line": 5, "column": 7 }, "end": { "line": 5, "column": 10 } } }, "3": { "name": "(anonymous_3)", "line": 9, "loc": { "start": { "line": 9, "column": 7 }, "end": { "line": 9, "column": 20 } } } }, "statementMap": { "1": { "start": { "line": 3, "column": 15 }, "end": { "line": 14, "column": 1 } }, "2": { "start": { "line": 4, "column": 2 }, "end": { "line": 13, "column": 5 } }, "3": { "start": { "line": 6, "column": 6 }, "end": { "line": 6, "column": 36 } }, "4": { "start": { "line": 10, "column": 6 }, "end": { "line": 10, "column": 48 } }, "5": { "start": { "line": 11, "column": 6 }, "end": { "line": 11, "column": 19 } } }, "branchMap": {}, "code": ["import { computed } from '@ember/object';", "", "export default function(property) {", "  return computed(property, {", "    get() {", "      return this.get(property)/100;", "    },", "", "    set(key, value) {", "      this.set(property, Math.floor(value*100));", "      return value;", "    }", "  });", "}", ""] };
   }
   __cov_dC1sCU00j$yMG85gmYfPxQ = __cov_dC1sCU00j$yMG85gmYfPxQ['app/utils/dollars.js'];
   var computed = Ember.computed;
});