define('prison-rideshare-ui/models/slot', ['exports', 'ember-data'], function (exports, _emberData) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });

   var __cov_STt0gn38CntZeBMm4Z8YTg = Function('return this')();
   if (!__cov_STt0gn38CntZeBMm4Z8YTg.__coverage__) {
      __cov_STt0gn38CntZeBMm4Z8YTg.__coverage__ = {};
   }
   __cov_STt0gn38CntZeBMm4Z8YTg = __cov_STt0gn38CntZeBMm4Z8YTg.__coverage__;
   if (!__cov_STt0gn38CntZeBMm4Z8YTg['app/models/slot.js']) {
      __cov_STt0gn38CntZeBMm4Z8YTg['app/models/slot.js'] = { "path": "app/models/slot.js", "s": { "1": 0, "2": 0, "3": 0 }, "b": { "1": [0, 0] }, "f": { "1": 0 }, "fnMap": { "1": { "name": "(anonymous_1)", "line": 11, "loc": { "start": { "line": 11, "column": 53 }, "end": { "line": 11, "column": 64 } } } }, "statementMap": { "1": { "start": { "line": 12, "column": 4 }, "end": { "line": 12, "column": 36 } }, "2": { "start": { "line": 13, "column": 4 }, "end": { "line": 13, "column": 59 } }, "3": { "start": { "line": 15, "column": 4 }, "end": { "line": 15, "column": 50 } } }, "branchMap": { "1": { "line": 15, "type": "binary-expr", "locations": [{ "start": { "line": 15, "column": 11 }, "end": { "line": 15, "column": 22 } }, { "start": { "line": 15, "column": 26 }, "end": { "line": 15, "column": 49 } }] } }, "code": ["import DS from 'ember-data';", "import { computed } from '@ember/object';", "", "export default DS.Model.extend({", "  start: DS.attr('date'),", "  end: DS.attr('date'),", "  count: DS.attr('number'),", "", "  commitments: DS.hasMany({ async: false }),", "", "  isNotFull: computed('commitments.length', 'count', function() {", "    const count = this.get('count');", "    const commitmentCount = this.get('commitments.length');", "", "    return count === 0 || commitmentCount < count;", "  })", "});", ""] };
   }
   __cov_STt0gn38CntZeBMm4Z8YTg = __cov_STt0gn38CntZeBMm4Z8YTg['app/models/slot.js'];
   var computed = Ember.computed;
   exports.default = _emberData.default.Model.extend({ start: _emberData.default.attr('date'), end: _emberData.default.attr('date'), count: _emberData.default.attr('number'), commitments: _emberData.default.hasMany({ async: false }), isNotFull: computed('commitments.length', 'count', function () {
         __cov_STt0gn38CntZeBMm4Z8YTg.f['1']++;__cov_STt0gn38CntZeBMm4Z8YTg.s['1']++;var count = this.get('count');__cov_STt0gn38CntZeBMm4Z8YTg.s['2']++;var commitmentCount = this.get('commitments.length');__cov_STt0gn38CntZeBMm4Z8YTg.s['3']++;return (__cov_STt0gn38CntZeBMm4Z8YTg.b['1'][0]++, count === 0) || (__cov_STt0gn38CntZeBMm4Z8YTg.b['1'][1]++, commitmentCount < count);
      }) });
});