define('prison-rideshare-ui/models/reimbursement', ['exports', 'ember-data', 'prison-rideshare-ui/utils/dollars'], function (exports, _emberData, _dollars) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });

   var __cov_w53h8jimGr4ogW1vP6lNPg = Function('return this')();
   if (!__cov_w53h8jimGr4ogW1vP6lNPg.__coverage__) {
      __cov_w53h8jimGr4ogW1vP6lNPg.__coverage__ = {};
   }
   __cov_w53h8jimGr4ogW1vP6lNPg = __cov_w53h8jimGr4ogW1vP6lNPg.__coverage__;
   if (!__cov_w53h8jimGr4ogW1vP6lNPg['app/models/reimbursement.js']) {
      __cov_w53h8jimGr4ogW1vP6lNPg['app/models/reimbursement.js'] = { "path": "app/models/reimbursement.js", "s": {}, "b": {}, "f": {}, "fnMap": {}, "statementMap": {}, "branchMap": {}, "code": ["import DS from 'ember-data';", "import dollars from 'prison-rideshare-ui/utils/dollars';", "", "export default DS.Model.extend({", "  foodExpenses: DS.attr('number', {defaultValue: 0}),", "  carExpenses: DS.attr('number', {defaultValue: 0}),", "", "  person: DS.belongsTo(),", "  donation: DS.attr('boolean'),", "  processed: DS.attr('boolean'),", "", "  foodExpensesDollars: dollars('foodExpenses'),", "  carExpensesDollars: dollars('carExpenses'),", "", "  ride: DS.belongsTo(),", "", "  insertedAt: DS.attr('date')", "});", ""] };
   }
   __cov_w53h8jimGr4ogW1vP6lNPg = __cov_w53h8jimGr4ogW1vP6lNPg['app/models/reimbursement.js'];
   exports.default = _emberData.default.Model.extend({ foodExpenses: _emberData.default.attr('number', { defaultValue: 0 }), carExpenses: _emberData.default.attr('number', { defaultValue: 0 }), person: _emberData.default.belongsTo(), donation: _emberData.default.attr('boolean'), processed: _emberData.default.attr('boolean'), foodExpensesDollars: (0, _dollars.default)('foodExpenses'), carExpensesDollars: (0, _dollars.default)('carExpenses'), ride: _emberData.default.belongsTo(), insertedAt: _emberData.default.attr('date') });
});