define('prison-rideshare-ui/templates/components/ride-person', ['exports'], function (exports) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });

   var __cov_5EbDfYVWhWzR7mPtjxi0Cw = Function('return this')();
   if (!__cov_5EbDfYVWhWzR7mPtjxi0Cw.__coverage__) {
      __cov_5EbDfYVWhWzR7mPtjxi0Cw.__coverage__ = {};
   }
   __cov_5EbDfYVWhWzR7mPtjxi0Cw = __cov_5EbDfYVWhWzR7mPtjxi0Cw.__coverage__;
   if (!__cov_5EbDfYVWhWzR7mPtjxi0Cw['app/templates/components/ride-person.hbs']) {
      __cov_5EbDfYVWhWzR7mPtjxi0Cw['app/templates/components/ride-person.hbs'] = { "path": "app/templates/components/ride-person.hbs", "s": {}, "b": {}, "f": {}, "fnMap": {}, "statementMap": {}, "branchMap": {}, "code": ["export default Ember.HTMLBars.template({\"id\":\"d35Bb7ti\",\"block\":\"{\\\"symbols\\\":[\\\"person\\\"],\\\"statements\\\":[[4,\\\"if\\\",[[20,[\\\"person\\\"]]],null,{\\\"statements\\\":[[0,\\\"  \\\"],[6,\\\"span\\\"],[9,\\\"class\\\",\\\"ride-person\\\"],[7],[0,\\\"\\\\n    \\\"],[1,[25,\\\"person-badge\\\",null,[[\\\"person\\\",\\\"property\\\",\\\"clear\\\"],[[20,[\\\"person\\\"]],[20,[\\\"property\\\"]],[25,\\\"action\\\",[[19,0,[]],\\\"clear\\\"],null]]]],false],[0,\\\"\\\\n  \\\"],[8],[0,\\\"\\\\n\\\"]],\\\"parameters\\\":[]},{\\\"statements\\\":[[4,\\\"paper-select\\\",null,[[\\\"selected\\\",\\\"options\\\",\\\"onChange\\\",\\\"allowClear\\\",\\\"matcher\\\"],[[20,[\\\"person\\\"]],[20,[\\\"people\\\"]],[20,[\\\"onChange\\\"]],true,[25,\\\"action\\\",[[19,0,[]],\\\"match\\\"],null]]],{\\\"statements\\\":[[0,\\\"    \\\"],[1,[19,1,[\\\"name\\\"]],false],[0,\\\"\\\\n\\\"]],\\\"parameters\\\":[1]},null]],\\\"parameters\\\":[]}]],\\\"hasEval\\\":false}\",\"meta\":{\"moduleName\":\"prison-rideshare-ui/templates/components/ride-person.hbs\"}});"] };
   }
   __cov_5EbDfYVWhWzR7mPtjxi0Cw = __cov_5EbDfYVWhWzR7mPtjxi0Cw['app/templates/components/ride-person.hbs'];
   exports.default = Ember.HTMLBars.template({ 'id': 'd35Bb7ti', 'block': '{"symbols":["person"],"statements":[[4,"if",[[20,["person"]]],null,{"statements":[[0,"  "],[6,"span"],[9,"class","ride-person"],[7],[0,"\\n    "],[1,[25,"person-badge",null,[["person","property","clear"],[[20,["person"]],[20,["property"]],[25,"action",[[19,0,[]],"clear"],null]]]],false],[0,"\\n  "],[8],[0,"\\n"]],"parameters":[]},{"statements":[[4,"paper-select",null,[["selected","options","onChange","allowClear","matcher"],[[20,["person"]],[20,["people"]],[20,["onChange"]],true,[25,"action",[[19,0,[]],"match"],null]]],{"statements":[[0,"    "],[1,[19,1,["name"]],false],[0,"\\n"]],"parameters":[1]},null]],"parameters":[]}]],"hasEval":false}', 'meta': { 'moduleName': 'prison-rideshare-ui/templates/components/ride-person.hbs' } });
});