define('prison-rideshare-ui/routes/index', ['exports', 'prison-rideshare-ui/mixins/authenticated-route'], function (exports, _authenticatedRoute) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });

   var __cov_ah7YgNsEzZJH5pHDWC6yVA = Function('return this')();
   if (!__cov_ah7YgNsEzZJH5pHDWC6yVA.__coverage__) {
      __cov_ah7YgNsEzZJH5pHDWC6yVA.__coverage__ = {};
   }
   __cov_ah7YgNsEzZJH5pHDWC6yVA = __cov_ah7YgNsEzZJH5pHDWC6yVA.__coverage__;
   if (!__cov_ah7YgNsEzZJH5pHDWC6yVA['app/routes/index.js']) {
      __cov_ah7YgNsEzZJH5pHDWC6yVA['app/routes/index.js'] = { "path": "app/routes/index.js", "s": { "1": 0, "2": 0, "3": 0, "4": 0 }, "b": { "1": [0, 0], "2": [0, 0] }, "f": { "1": 0 }, "fnMap": { "1": { "name": "(anonymous_1)", "line": 9, "loc": { "start": { "line": 9, "column": 13 }, "end": { "line": 9, "column": 16 } } } }, "statementMap": { "1": { "start": { "line": 11, "column": 4 }, "end": { "line": 17, "column": 7 } }, "2": { "start": { "line": 12, "column": 6 }, "end": { "line": 16, "column": 7 } }, "3": { "start": { "line": 13, "column": 8 }, "end": { "line": 13, "column": 35 } }, "4": { "start": { "line": 15, "column": 8 }, "end": { "line": 15, "column": 41 } } }, "branchMap": { "1": { "line": 12, "type": "if", "locations": [{ "start": { "line": 12, "column": 6 }, "end": { "line": 12, "column": 6 } }, { "start": { "line": 12, "column": 6 }, "end": { "line": 12, "column": 6 } }] }, "2": { "line": 12, "type": "binary-expr", "locations": [{ "start": { "line": 12, "column": 10 }, "end": { "line": 12, "column": 45 } }, { "start": { "line": 12, "column": 49 }, "end": { "line": 12, "column": 86 } }] } }, "code": ["import { inject as service } from '@ember/service';", "import Route from '@ember/routing/route';", "import AuthenticatedRoute from 'prison-rideshare-ui/mixins/authenticated-route';", "", "export default Route.extend(AuthenticatedRoute, {", "  account: service(),", "  session: service(),", "", "  beforeModel() {", "    // FIXME this is loading twice, also in application", "    return this.get('account').loadCurrentUser().then(() => {", "      if (this.get('session.isAuthenticated') && this.get('session.currentUser.admin')) {", "        this.transitionTo('rides');", "      } else {", "        this.transitionTo('reports.new');", "      }", "    });", "  }", "});", ""] };
   }
   __cov_ah7YgNsEzZJH5pHDWC6yVA = __cov_ah7YgNsEzZJH5pHDWC6yVA['app/routes/index.js'];
   var service = Ember.inject.service;
   var Route = Ember.Route;
   exports.default = Route.extend(_authenticatedRoute.default, { account: service(), session: service(), beforeModel: function beforeModel() {
         var _this = this;

         __cov_ah7YgNsEzZJH5pHDWC6yVA.f['1']++;__cov_ah7YgNsEzZJH5pHDWC6yVA.s['1']++;return this.get('account').loadCurrentUser().then(function () {
            __cov_ah7YgNsEzZJH5pHDWC6yVA.s['2']++;if ((__cov_ah7YgNsEzZJH5pHDWC6yVA.b['2'][0]++, _this.get('session.isAuthenticated')) && (__cov_ah7YgNsEzZJH5pHDWC6yVA.b['2'][1]++, _this.get('session.currentUser.admin'))) {
               __cov_ah7YgNsEzZJH5pHDWC6yVA.b['1'][0]++;__cov_ah7YgNsEzZJH5pHDWC6yVA.s['3']++;_this.transitionTo('rides');
            } else {
               __cov_ah7YgNsEzZJH5pHDWC6yVA.b['1'][1]++;__cov_ah7YgNsEzZJH5pHDWC6yVA.s['4']++;_this.transitionTo('reports.new');
            }
         });
      }
   });
});