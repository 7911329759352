define('prison-rideshare-ui/routes/users', ['exports', 'prison-rideshare-ui/mixins/authenticated-route'], function (exports, _authenticatedRoute) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });

   var __cov_t$QGoc$sZStE7IVDRiXRcQ = Function('return this')();
   if (!__cov_t$QGoc$sZStE7IVDRiXRcQ.__coverage__) {
      __cov_t$QGoc$sZStE7IVDRiXRcQ.__coverage__ = {};
   }
   __cov_t$QGoc$sZStE7IVDRiXRcQ = __cov_t$QGoc$sZStE7IVDRiXRcQ.__coverage__;
   if (!__cov_t$QGoc$sZStE7IVDRiXRcQ['app/routes/users.js']) {
      __cov_t$QGoc$sZStE7IVDRiXRcQ['app/routes/users.js'] = { "path": "app/routes/users.js", "s": { "1": 0 }, "b": {}, "f": { "1": 0 }, "fnMap": { "1": { "name": "(anonymous_1)", "line": 5, "loc": { "start": { "line": 5, "column": 7 }, "end": { "line": 5, "column": 10 } } } }, "statementMap": { "1": { "start": { "line": 6, "column": 4 }, "end": { "line": 6, "column": 45 } } }, "branchMap": {}, "code": ["import Route from '@ember/routing/route';", "import AuthenticatedRoute from 'prison-rideshare-ui/mixins/authenticated-route';", "", "export default Route.extend(AuthenticatedRoute, {", "  model() {", "    return this.get('store').findAll('user');", "  },", "", "  titleToken: 'Users'", "});", ""] };
   }
   __cov_t$QGoc$sZStE7IVDRiXRcQ = __cov_t$QGoc$sZStE7IVDRiXRcQ['app/routes/users.js'];
   var Route = Ember.Route;
   exports.default = Route.extend(_authenticatedRoute.default, {
      model: function model() {
         __cov_t$QGoc$sZStE7IVDRiXRcQ.f['1']++;__cov_t$QGoc$sZStE7IVDRiXRcQ.s['1']++;return this.get('store').findAll('user');
      },
      titleToken: 'Users' });
});