define('prison-rideshare-ui/routes/reimbursements', ['exports', 'prison-rideshare-ui/mixins/authenticated-route'], function (exports, _authenticatedRoute) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });

   var __cov_il8BSRFScP6ac8zvJ3wTEg = Function('return this')();
   if (!__cov_il8BSRFScP6ac8zvJ3wTEg.__coverage__) {
      __cov_il8BSRFScP6ac8zvJ3wTEg.__coverage__ = {};
   }
   __cov_il8BSRFScP6ac8zvJ3wTEg = __cov_il8BSRFScP6ac8zvJ3wTEg.__coverage__;
   if (!__cov_il8BSRFScP6ac8zvJ3wTEg['app/routes/reimbursements.js']) {
      __cov_il8BSRFScP6ac8zvJ3wTEg['app/routes/reimbursements.js'] = { "path": "app/routes/reimbursements.js", "s": { "1": 0, "2": 0 }, "b": {}, "f": { "1": 0, "2": 0 }, "fnMap": { "1": { "name": "(anonymous_1)", "line": 6, "loc": { "start": { "line": 6, "column": 7 }, "end": { "line": 6, "column": 10 } } }, "2": { "name": "(anonymous_2)", "line": 10, "loc": { "start": { "line": 10, "column": 12 }, "end": { "line": 10, "column": 29 } } } }, "statementMap": { "1": { "start": { "line": 7, "column": 4 }, "end": { "line": 7, "column": 47 } }, "2": { "start": { "line": 12, "column": 4 }, "end": { "line": 12, "column": 45 } } }, "branchMap": {}, "code": ["import { all } from 'rsvp';", "import Route from '@ember/routing/route';", "import AuthenticatedRoute from 'prison-rideshare-ui/mixins/authenticated-route';", "", "export default Route.extend(AuthenticatedRoute, {", "  model() {", "    return this.store.findAll('reimbursement');", "  },", "", "  afterModel(reimbursements) {", "    // TODO coalesceFindRequests?", "    return all(reimbursements.mapBy('ride'));", "  },", "", "  titleToken: 'Reimbursements'", "});", ""] };
   }
   __cov_il8BSRFScP6ac8zvJ3wTEg = __cov_il8BSRFScP6ac8zvJ3wTEg['app/routes/reimbursements.js'];
   var all = Ember.RSVP.all;
   var Route = Ember.Route;
   exports.default = Route.extend(_authenticatedRoute.default, {
      model: function model() {
         __cov_il8BSRFScP6ac8zvJ3wTEg.f['1']++;__cov_il8BSRFScP6ac8zvJ3wTEg.s['1']++;return this.store.findAll('reimbursement');
      },
      afterModel: function afterModel(reimbursements) {
         __cov_il8BSRFScP6ac8zvJ3wTEg.f['2']++;__cov_il8BSRFScP6ac8zvJ3wTEg.s['2']++;return all(reimbursements.mapBy('ride'));
      },
      titleToken: 'Reimbursements' });
});