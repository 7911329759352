define('prison-rideshare-ui/templates/head', ['exports'], function (exports) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });

   var __cov_OLTraeU0xdPU9$vEfmwOYw = Function('return this')();
   if (!__cov_OLTraeU0xdPU9$vEfmwOYw.__coverage__) {
      __cov_OLTraeU0xdPU9$vEfmwOYw.__coverage__ = {};
   }
   __cov_OLTraeU0xdPU9$vEfmwOYw = __cov_OLTraeU0xdPU9$vEfmwOYw.__coverage__;
   if (!__cov_OLTraeU0xdPU9$vEfmwOYw['app/templates/head.hbs']) {
      __cov_OLTraeU0xdPU9$vEfmwOYw['app/templates/head.hbs'] = { "path": "app/templates/head.hbs", "s": {}, "b": {}, "f": {}, "fnMap": {}, "statementMap": {}, "branchMap": {}, "code": ["export default Ember.HTMLBars.template({\"id\":\"FJWRYBVx\",\"block\":\"{\\\"symbols\\\":[],\\\"statements\\\":[[6,\\\"title\\\"],[7],[1,[20,[\\\"model\\\",\\\"title\\\"]],false],[8],[0,\\\"\\\\n\\\"]],\\\"hasEval\\\":false}\",\"meta\":{\"moduleName\":\"prison-rideshare-ui/templates/head.hbs\"}});"] };
   }
   __cov_OLTraeU0xdPU9$vEfmwOYw = __cov_OLTraeU0xdPU9$vEfmwOYw['app/templates/head.hbs'];
   exports.default = Ember.HTMLBars.template({ 'id': 'FJWRYBVx', 'block': '{"symbols":[],"statements":[[6,"title"],[7],[1,[20,["model","title"]],false],[8],[0,"\\n"]],"hasEval":false}', 'meta': { 'moduleName': 'prison-rideshare-ui/templates/head.hbs' } });
});