define('prison-rideshare-ui/routes/debts', ['exports', 'prison-rideshare-ui/mixins/authenticated-route'], function (exports, _authenticatedRoute) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });

   var __cov_vEVPY5kgMzk8Y7VQavIfag = Function('return this')();
   if (!__cov_vEVPY5kgMzk8Y7VQavIfag.__coverage__) {
      __cov_vEVPY5kgMzk8Y7VQavIfag.__coverage__ = {};
   }
   __cov_vEVPY5kgMzk8Y7VQavIfag = __cov_vEVPY5kgMzk8Y7VQavIfag.__coverage__;
   if (!__cov_vEVPY5kgMzk8Y7VQavIfag['app/routes/debts.js']) {
      __cov_vEVPY5kgMzk8Y7VQavIfag['app/routes/debts.js'] = { "path": "app/routes/debts.js", "s": { "1": 0 }, "b": {}, "f": { "1": 0 }, "fnMap": { "1": { "name": "(anonymous_1)", "line": 5, "loc": { "start": { "line": 5, "column": 7 }, "end": { "line": 5, "column": 10 } } } }, "statementMap": { "1": { "start": { "line": 6, "column": 4 }, "end": { "line": 6, "column": 38 } } }, "branchMap": {}, "code": ["import Route from '@ember/routing/route';", "import AuthenticatedRoute from 'prison-rideshare-ui/mixins/authenticated-route';", "", "export default Route.extend(AuthenticatedRoute, {", "  model() {", "    return this.store.findAll('debt');", "  },", "", "  titleToken: 'Debts'", "});", ""] };
   }
   __cov_vEVPY5kgMzk8Y7VQavIfag = __cov_vEVPY5kgMzk8Y7VQavIfag['app/routes/debts.js'];
   var Route = Ember.Route;
   exports.default = Route.extend(_authenticatedRoute.default, {
      model: function model() {
         __cov_vEVPY5kgMzk8Y7VQavIfag.f['1']++;__cov_vEVPY5kgMzk8Y7VQavIfag.s['1']++;return this.store.findAll('debt');
      },
      titleToken: 'Debts' });
});