define('prison-rideshare-ui/mixins/authenticated-route', ['exports'], function (exports) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });

   var __cov_mkRqX5E0OijG9IfLlpT6Jw = Function('return this')();
   if (!__cov_mkRqX5E0OijG9IfLlpT6Jw.__coverage__) {
      __cov_mkRqX5E0OijG9IfLlpT6Jw.__coverage__ = {};
   }
   __cov_mkRqX5E0OijG9IfLlpT6Jw = __cov_mkRqX5E0OijG9IfLlpT6Jw.__coverage__;
   if (!__cov_mkRqX5E0OijG9IfLlpT6Jw['app/mixins/authenticated-route.js']) {
      __cov_mkRqX5E0OijG9IfLlpT6Jw['app/mixins/authenticated-route.js'] = { "path": "app/mixins/authenticated-route.js", "s": { "1": 0, "2": 0 }, "b": { "1": [0, 0] }, "f": { "1": 0 }, "fnMap": { "1": { "name": "(anonymous_1)", "line": 7, "loc": { "start": { "line": 7, "column": 13 }, "end": { "line": 7, "column": 16 } } } }, "statementMap": { "1": { "start": { "line": 8, "column": 4 }, "end": { "line": 10, "column": 5 } }, "2": { "start": { "line": 9, "column": 6 }, "end": { "line": 9, "column": 33 } } }, "branchMap": { "1": { "line": 8, "type": "if", "locations": [{ "start": { "line": 8, "column": 4 }, "end": { "line": 8, "column": 4 } }, { "start": { "line": 8, "column": 4 }, "end": { "line": 8, "column": 4 } }] } }, "code": ["import { inject as service } from '@ember/service';", "import Mixin from '@ember/object/mixin';", "", "export default Mixin.create({", "  session: service(),", "", "  beforeModel() {", "    if (!this.get('session.isAuthenticated')) {", "      this.transitionTo('login');", "    }", "  }", "});", ""] };
   }
   __cov_mkRqX5E0OijG9IfLlpT6Jw = __cov_mkRqX5E0OijG9IfLlpT6Jw['app/mixins/authenticated-route.js'];
   var service = Ember.inject.service;
   var Mixin = Ember.Mixin;
   exports.default = Mixin.create({ session: service(), beforeModel: function beforeModel() {
         __cov_mkRqX5E0OijG9IfLlpT6Jw.f['1']++;__cov_mkRqX5E0OijG9IfLlpT6Jw.s['1']++;if (!this.get('session.isAuthenticated')) {
            __cov_mkRqX5E0OijG9IfLlpT6Jw.b['1'][0]++;__cov_mkRqX5E0OijG9IfLlpT6Jw.s['2']++;this.transitionTo('login');
         } else {
            __cov_mkRqX5E0OijG9IfLlpT6Jw.b['1'][1]++;
         }
      }
   });
});