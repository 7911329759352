define('prison-rideshare-ui/components/calendar-days', ['exports', 'ember-power-calendar/components/power-calendar/days'], function (exports, _days) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });

   var __cov_hZeAomQAisMqWFNQdE1xlg = Function('return this')();
   if (!__cov_hZeAomQAisMqWFNQdE1xlg.__coverage__) {
      __cov_hZeAomQAisMqWFNQdE1xlg.__coverage__ = {};
   }
   __cov_hZeAomQAisMqWFNQdE1xlg = __cov_hZeAomQAisMqWFNQdE1xlg.__coverage__;
   if (!__cov_hZeAomQAisMqWFNQdE1xlg['app/components/calendar-days.js']) {
      __cov_hZeAomQAisMqWFNQdE1xlg['app/components/calendar-days.js'] = { "path": "app/components/calendar-days.js", "s": {}, "b": {}, "f": {}, "fnMap": {}, "statementMap": {}, "branchMap": {}, "code": ["import Days from 'ember-power-calendar/components/power-calendar/days';", "", "export default Days;", ""] };
   }
   __cov_hZeAomQAisMqWFNQdE1xlg = __cov_hZeAomQAisMqWFNQdE1xlg['app/components/calendar-days.js'];
   exports.default = _days.default;
});