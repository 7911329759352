define('prison-rideshare-ui/templates/components/toolbar-header', ['exports'], function (exports) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });

   var __cov_Yd8b3CUkBjKYQszK5MtYVg = Function('return this')();
   if (!__cov_Yd8b3CUkBjKYQszK5MtYVg.__coverage__) {
      __cov_Yd8b3CUkBjKYQszK5MtYVg.__coverage__ = {};
   }
   __cov_Yd8b3CUkBjKYQszK5MtYVg = __cov_Yd8b3CUkBjKYQszK5MtYVg.__coverage__;
   if (!__cov_Yd8b3CUkBjKYQszK5MtYVg['app/templates/components/toolbar-header.hbs']) {
      __cov_Yd8b3CUkBjKYQszK5MtYVg['app/templates/components/toolbar-header.hbs'] = { "path": "app/templates/components/toolbar-header.hbs", "s": {}, "b": {}, "f": {}, "fnMap": {}, "statementMap": {}, "branchMap": {}, "code": ["export default Ember.HTMLBars.template({\"id\":\"1PldGMl5\",\"block\":\"{\\\"symbols\\\":[\\\"toolbar\\\",\\\"item\\\",\\\"&default\\\"],\\\"statements\\\":[[4,\\\"paper-toolbar\\\",null,null,{\\\"statements\\\":[[4,\\\"component\\\",[[19,1,[\\\"tools\\\"]]],null,{\\\"statements\\\":[[4,\\\"paper-button\\\",null,[[\\\"iconButton\\\",\\\"onClick\\\",\\\"class\\\"],[true,[25,\\\"action\\\",[[19,0,[]],\\\"toggleSidebar\\\"],null],\\\"hide-gt-sm\\\"]],{\\\"statements\\\":[[0,\\\"      \\\"],[1,[25,\\\"paper-icon\\\",[\\\"menu\\\"],null],false],[0,\\\"\\\\n\\\"]],\\\"parameters\\\":[]},null],[0,\\\"    \\\"],[6,\\\"h2\\\"],[7],[1,[18,\\\"title\\\"],false],[8],[0,\\\"\\\\n    \\\"],[4,\\\"paper-chips\\\",null,[[\\\"readOnly\\\",\\\"content\\\"],[true,[20,[\\\"chips\\\"]]]],{\\\"statements\\\":[[6,\\\"span\\\"],[10,\\\"title\\\",[26,[[19,2,[\\\"title\\\"]]]]],[7],[1,[19,2,[\\\"label\\\"]],false],[8]],\\\"parameters\\\":[2]},null],[0,\\\"\\\\n    \\\"],[6,\\\"span\\\"],[9,\\\"class\\\",\\\"flex\\\"],[7],[8],[0,\\\"\\\\n    \\\"],[11,3],[0,\\\"\\\\n\\\"]],\\\"parameters\\\":[]},null]],\\\"parameters\\\":[1]},null]],\\\"hasEval\\\":false}\",\"meta\":{\"moduleName\":\"prison-rideshare-ui/templates/components/toolbar-header.hbs\"}});"] };
   }
   __cov_Yd8b3CUkBjKYQszK5MtYVg = __cov_Yd8b3CUkBjKYQszK5MtYVg['app/templates/components/toolbar-header.hbs'];
   exports.default = Ember.HTMLBars.template({ 'id': '1PldGMl5', 'block': '{"symbols":["toolbar","item","&default"],"statements":[[4,"paper-toolbar",null,null,{"statements":[[4,"component",[[19,1,["tools"]]],null,{"statements":[[4,"paper-button",null,[["iconButton","onClick","class"],[true,[25,"action",[[19,0,[]],"toggleSidebar"],null],"hide-gt-sm"]],{"statements":[[0,"      "],[1,[25,"paper-icon",["menu"],null],false],[0,"\\n"]],"parameters":[]},null],[0,"    "],[6,"h2"],[7],[1,[18,"title"],false],[8],[0,"\\n    "],[4,"paper-chips",null,[["readOnly","content"],[true,[20,["chips"]]]],{"statements":[[6,"span"],[10,"title",[26,[[19,2,["title"]]]]],[7],[1,[19,2,["label"]],false],[8]],"parameters":[2]},null],[0,"\\n    "],[6,"span"],[9,"class","flex"],[7],[8],[0,"\\n    "],[11,3],[0,"\\n"]],"parameters":[]},null]],"parameters":[1]},null]],"hasEval":false}', 'meta': { 'moduleName': 'prison-rideshare-ui/templates/components/toolbar-header.hbs' } });
});