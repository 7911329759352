define('prison-rideshare-ui/templates/components/calendar-day', ['exports'], function (exports) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });

   var __cov_Cqn4JWLLFlZe7QQ6nJSb3w = Function('return this')();
   if (!__cov_Cqn4JWLLFlZe7QQ6nJSb3w.__coverage__) {
      __cov_Cqn4JWLLFlZe7QQ6nJSb3w.__coverage__ = {};
   }
   __cov_Cqn4JWLLFlZe7QQ6nJSb3w = __cov_Cqn4JWLLFlZe7QQ6nJSb3w.__coverage__;
   if (!__cov_Cqn4JWLLFlZe7QQ6nJSb3w['app/templates/components/calendar-day.hbs']) {
      __cov_Cqn4JWLLFlZe7QQ6nJSb3w['app/templates/components/calendar-day.hbs'] = { "path": "app/templates/components/calendar-day.hbs", "s": {}, "b": {}, "f": {}, "fnMap": {}, "statementMap": {}, "branchMap": {}, "code": ["export default Ember.HTMLBars.template({\"id\":\"Vg7TWQXX\",\"block\":\"{\\\"symbols\\\":[\\\"slot\\\"],\\\"statements\\\":[[6,\\\"div\\\"],[9,\\\"class\\\",\\\"day\\\"],[7],[1,[20,[\\\"day\\\",\\\"number\\\"]],false],[8],[0,\\\"\\\\n\\\\n\\\"],[4,\\\"each\\\",[[20,[\\\"daySlots\\\"]]],null,{\\\"statements\\\":[[0,\\\"  \\\"],[1,[25,\\\"calendar-slot\\\",null,[[\\\"slot\\\",\\\"person\\\",\\\"count\\\",\\\"setViewingSlot\\\"],[[19,1,[]],[20,[\\\"person\\\"]],[20,[\\\"count\\\"]],[20,[\\\"setViewingSlot\\\"]]]]],false],[0,\\\"\\\\n\\\"]],\\\"parameters\\\":[1]},null]],\\\"hasEval\\\":false}\",\"meta\":{\"moduleName\":\"prison-rideshare-ui/templates/components/calendar-day.hbs\"}});"] };
   }
   __cov_Cqn4JWLLFlZe7QQ6nJSb3w = __cov_Cqn4JWLLFlZe7QQ6nJSb3w['app/templates/components/calendar-day.hbs'];
   exports.default = Ember.HTMLBars.template({ 'id': 'Vg7TWQXX', 'block': '{"symbols":["slot"],"statements":[[6,"div"],[9,"class","day"],[7],[1,[20,["day","number"]],false],[8],[0,"\\n\\n"],[4,"each",[[20,["daySlots"]]],null,{"statements":[[0,"  "],[1,[25,"calendar-slot",null,[["slot","person","count","setViewingSlot"],[[19,1,[]],[20,["person"]],[20,["count"]],[20,["setViewingSlot"]]]]],false],[0,"\\n"]],"parameters":[1]},null]],"hasEval":false}', 'meta': { 'moduleName': 'prison-rideshare-ui/templates/components/calendar-day.hbs' } });
});