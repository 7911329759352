define('prison-rideshare-ui/highcharts-configs/application', ['exports'], function (exports) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });

   exports.default = function (defaultOptions) {
      __cov_4tjUV36WJM2jd0eeAg1kug.f['1']++;__cov_4tjUV36WJM2jd0eeAg1kug.s['2']++;defaultOptions.chart = { style: { fontFamily: 'inherit' } };__cov_4tjUV36WJM2jd0eeAg1kug.s['3']++;return defaultOptions;
   };

   var __cov_4tjUV36WJM2jd0eeAg1kug = Function('return this')();
   if (!__cov_4tjUV36WJM2jd0eeAg1kug.__coverage__) {
      __cov_4tjUV36WJM2jd0eeAg1kug.__coverage__ = {};
   }
   __cov_4tjUV36WJM2jd0eeAg1kug = __cov_4tjUV36WJM2jd0eeAg1kug.__coverage__;
   if (!__cov_4tjUV36WJM2jd0eeAg1kug['app/highcharts-configs/application.js']) {
      __cov_4tjUV36WJM2jd0eeAg1kug['app/highcharts-configs/application.js'] = { "path": "app/highcharts-configs/application.js", "s": { "1": 1, "2": 0, "3": 0 }, "b": {}, "f": { "1": 0 }, "fnMap": { "1": { "name": "(anonymous_1)", "line": 1, "loc": { "start": { "line": 1, "column": 15 }, "end": { "line": 1, "column": 40 } } } }, "statementMap": { "1": { "start": { "line": 1, "column": 15 }, "end": { "line": 9, "column": 1 } }, "2": { "start": { "line": 2, "column": 2 }, "end": { "line": 6, "column": 4 } }, "3": { "start": { "line": 8, "column": 2 }, "end": { "line": 8, "column": 24 } } }, "branchMap": {}, "code": ["export default function(defaultOptions) {", "  defaultOptions.chart = {", "    style: {", "      fontFamily: 'inherit'", "    }", "  };", "", "  return defaultOptions;", "}", ""] };
   }
   __cov_4tjUV36WJM2jd0eeAg1kug = __cov_4tjUV36WJM2jd0eeAg1kug['app/highcharts-configs/application.js'];
});