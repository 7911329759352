define('prison-rideshare-ui/controllers/application', ['exports'], function (exports) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });

   var __cov_qaGe43uoYZvGjbmStjfdBQ = Function('return this')();
   if (!__cov_qaGe43uoYZvGjbmStjfdBQ.__coverage__) {
      __cov_qaGe43uoYZvGjbmStjfdBQ.__coverage__ = {};
   }
   __cov_qaGe43uoYZvGjbmStjfdBQ = __cov_qaGe43uoYZvGjbmStjfdBQ.__coverage__;
   if (!__cov_qaGe43uoYZvGjbmStjfdBQ['app/controllers/application.js']) {
      __cov_qaGe43uoYZvGjbmStjfdBQ['app/controllers/application.js'] = { "path": "app/controllers/application.js", "s": { "1": 0, "2": 0, "3": 0, "4": 0, "5": 0, "6": 0 }, "b": { "1": [0, 0] }, "f": { "1": 0, "2": 0 }, "fnMap": { "1": { "name": "(anonymous_1)", "line": 10, "loc": { "start": { "line": 10, "column": 51 }, "end": { "line": 10, "column": 62 } } }, "2": { "name": "(anonymous_2)", "line": 21, "loc": { "start": { "line": 21, "column": 10 }, "end": { "line": 21, "column": 13 } } } }, "statementMap": { "1": { "start": { "line": 11, "column": 4 }, "end": { "line": 11, "column": 56 } }, "2": { "start": { "line": 13, "column": 4 }, "end": { "line": 17, "column": 5 } }, "3": { "start": { "line": 14, "column": 6 }, "end": { "line": 14, "column": 19 } }, "4": { "start": { "line": 16, "column": 6 }, "end": { "line": 16, "column": 23 } }, "5": { "start": { "line": 22, "column": 6 }, "end": { "line": 22, "column": 39 } }, "6": { "start": { "line": 23, "column": 6 }, "end": { "line": 23, "column": 29 } } }, "branchMap": { "1": { "line": 13, "type": "if", "locations": [{ "start": { "line": 13, "column": 4 }, "end": { "line": 13, "column": 4 } }, { "start": { "line": 13, "column": 4 }, "end": { "line": 13, "column": 4 } }] } }, "code": ["import { computed } from '@ember/object';", "import { inject as service } from '@ember/service';", "import Controller from '@ember/controller';", "", "export default Controller.extend({", "  sidebar: service(),", "  session: service(),", "  userSocket: service(),", "", "  userCount: computed('userSocket.present.length', function() {", "    const count = this.get('userSocket.present.length');", "", "    if (count > 1) {", "      return count;", "    } else {", "      return undefined;", "    }", "  }),", "", "  actions: {", "    logout() {", "      this.get('session').invalidate();", "      this.store.unloadAll();", "    }", "  }", "});", ""] };
   }
   __cov_qaGe43uoYZvGjbmStjfdBQ = __cov_qaGe43uoYZvGjbmStjfdBQ['app/controllers/application.js'];
   var computed = Ember.computed;
   var service = Ember.inject.service;
   var Controller = Ember.Controller;
   exports.default = Controller.extend({ sidebar: service(), session: service(), userSocket: service(), userCount: computed('userSocket.present.length', function () {
         __cov_qaGe43uoYZvGjbmStjfdBQ.f['1']++;__cov_qaGe43uoYZvGjbmStjfdBQ.s['1']++;var count = this.get('userSocket.present.length');__cov_qaGe43uoYZvGjbmStjfdBQ.s['2']++;if (count > 1) {
            __cov_qaGe43uoYZvGjbmStjfdBQ.b['1'][0]++;__cov_qaGe43uoYZvGjbmStjfdBQ.s['3']++;return count;
         } else {
            __cov_qaGe43uoYZvGjbmStjfdBQ.b['1'][1]++;__cov_qaGe43uoYZvGjbmStjfdBQ.s['4']++;return undefined;
         }
      }), actions: {
         logout: function logout() {
            __cov_qaGe43uoYZvGjbmStjfdBQ.f['2']++;__cov_qaGe43uoYZvGjbmStjfdBQ.s['5']++;this.get('session').invalidate();__cov_qaGe43uoYZvGjbmStjfdBQ.s['6']++;this.store.unloadAll();
         }
      } });
});