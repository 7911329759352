define('prison-rideshare-ui/templates/components/calendar-slot', ['exports'], function (exports) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });

   var __cov_K7l019nirOYCBjKuQkRjvA = Function('return this')();
   if (!__cov_K7l019nirOYCBjKuQkRjvA.__coverage__) {
      __cov_K7l019nirOYCBjKuQkRjvA.__coverage__ = {};
   }
   __cov_K7l019nirOYCBjKuQkRjvA = __cov_K7l019nirOYCBjKuQkRjvA.__coverage__;
   if (!__cov_K7l019nirOYCBjKuQkRjvA['app/templates/components/calendar-slot.hbs']) {
      __cov_K7l019nirOYCBjKuQkRjvA['app/templates/components/calendar-slot.hbs'] = { "path": "app/templates/components/calendar-slot.hbs", "s": {}, "b": {}, "f": {}, "fnMap": {}, "statementMap": {}, "branchMap": {}, "code": ["export default Ember.HTMLBars.template({\"id\":\"G/Bc1hBQ\",\"block\":\"{\\\"symbols\\\":[],\\\"statements\\\":[[6,\\\"div\\\"],[9,\\\"class\\\",\\\"slot\\\"],[7],[0,\\\"\\\\n\\\"],[4,\\\"if\\\",[[20,[\\\"count\\\"]]],null,{\\\"statements\\\":[[0,\\\"    \\\"],[6,\\\"span\\\"],[9,\\\"class\\\",\\\"hours\\\"],[7],[1,[18,\\\"timespan\\\"],false],[8],[0,\\\"\\\\n    \\\"],[6,\\\"span\\\"],[10,\\\"class\\\",[26,[\\\"count \\\",[25,\\\"if\\\",[[25,\\\"gt\\\",[[20,[\\\"slot\\\",\\\"commitments\\\",\\\"length\\\"]],0],null],\\\"committed-to\\\"],null]]]],[10,\\\"onClick\\\",[25,\\\"action\\\",[[19,0,[]],[20,[\\\"setViewingSlot\\\"]],[20,[\\\"slot\\\"]]],null],null],[7],[1,[18,\\\"capacity\\\"],false],[8],[0,\\\"\\\\n\\\"]],\\\"parameters\\\":[]},{\\\"statements\\\":[[4,\\\"paper-checkbox\\\",null,[[\\\"value\\\",\\\"disabled\\\",\\\"indeterminate\\\",\\\"onChange\\\"],[[20,[\\\"isCommittedTo\\\"]],[20,[\\\"disabled\\\"]],[20,[\\\"toggle\\\",\\\"isRunning\\\"]],[25,\\\"perform\\\",[[20,[\\\"toggle\\\"]]],null]]],{\\\"statements\\\":[[0,\\\"      \\\"],[6,\\\"span\\\"],[9,\\\"class\\\",\\\"hours\\\"],[7],[1,[18,\\\"timespan\\\"],false],[8],[0,\\\"\\\\n\\\"]],\\\"parameters\\\":[]},null]],\\\"parameters\\\":[]}],[8],[0,\\\"\\\\n\\\"]],\\\"hasEval\\\":false}\",\"meta\":{\"moduleName\":\"prison-rideshare-ui/templates/components/calendar-slot.hbs\"}});"] };
   }
   __cov_K7l019nirOYCBjKuQkRjvA = __cov_K7l019nirOYCBjKuQkRjvA['app/templates/components/calendar-slot.hbs'];
   exports.default = Ember.HTMLBars.template({ 'id': 'G/Bc1hBQ', 'block': '{"symbols":[],"statements":[[6,"div"],[9,"class","slot"],[7],[0,"\\n"],[4,"if",[[20,["count"]]],null,{"statements":[[0,"    "],[6,"span"],[9,"class","hours"],[7],[1,[18,"timespan"],false],[8],[0,"\\n    "],[6,"span"],[10,"class",[26,["count ",[25,"if",[[25,"gt",[[20,["slot","commitments","length"]],0],null],"committed-to"],null]]]],[10,"onClick",[25,"action",[[19,0,[]],[20,["setViewingSlot"]],[20,["slot"]]],null],null],[7],[1,[18,"capacity"],false],[8],[0,"\\n"]],"parameters":[]},{"statements":[[4,"paper-checkbox",null,[["value","disabled","indeterminate","onChange"],[[20,["isCommittedTo"]],[20,["disabled"]],[20,["toggle","isRunning"]],[25,"perform",[[20,["toggle"]]],null]]],{"statements":[[0,"      "],[6,"span"],[9,"class","hours"],[7],[1,[18,"timespan"],false],[8],[0,"\\n"]],"parameters":[]},null]],"parameters":[]}],[8],[0,"\\n"]],"hasEval":false}', 'meta': { 'moduleName': 'prison-rideshare-ui/templates/components/calendar-slot.hbs' } });
});