define('prison-rideshare-ui/authenticators/application', ['exports', 'ember-simple-auth/authenticators/oauth2-password-grant', 'prison-rideshare-ui/config/environment'], function (exports, _oauth2PasswordGrant, _environment) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });

   var __cov_KmdfYlKvgoRiI8c$vTxAHg = Function('return this')();
   if (!__cov_KmdfYlKvgoRiI8c$vTxAHg.__coverage__) {
      __cov_KmdfYlKvgoRiI8c$vTxAHg.__coverage__ = {};
   }
   __cov_KmdfYlKvgoRiI8c$vTxAHg = __cov_KmdfYlKvgoRiI8c$vTxAHg.__coverage__;
   if (!__cov_KmdfYlKvgoRiI8c$vTxAHg['app/authenticators/application.js']) {
      __cov_KmdfYlKvgoRiI8c$vTxAHg['app/authenticators/application.js'] = { "path": "app/authenticators/application.js", "s": {}, "b": { "1": [0, 0] }, "f": {}, "fnMap": {}, "statementMap": {}, "branchMap": { "1": { "line": 7, "type": "cond-expr", "locations": [{ "start": { "line": 7, "column": 43 }, "end": { "line": 7, "column": 45 } }, { "start": { "line": 7, "column": 48 }, "end": { "line": 7, "column": 62 } }] } }, "code": ["import Ember from 'ember';", "", "import OAuth2PasswordGrant from 'ember-simple-auth/authenticators/oauth2-password-grant';", "import config from 'prison-rideshare-ui/config/environment';", "", "export default OAuth2PasswordGrant.extend({", "  serverTokenEndpoint: `${(Ember.testing ? '' : config.DS.host)}/${config.DS.namespace}/token`", "});", ""] };
   }
   __cov_KmdfYlKvgoRiI8c$vTxAHg = __cov_KmdfYlKvgoRiI8c$vTxAHg['app/authenticators/application.js'];
   exports.default = _oauth2PasswordGrant.default.extend({ serverTokenEndpoint: (Ember.testing ? (__cov_KmdfYlKvgoRiI8c$vTxAHg.b['1'][0]++, '') : (__cov_KmdfYlKvgoRiI8c$vTxAHg.b['1'][1]++, _environment.default.DS.host)) + '/' + _environment.default.DS.namespace + '/token' });
});