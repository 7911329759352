define('prison-rideshare-ui/templates/components/donation-icon', ['exports'], function (exports) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });

   var __cov_dzjgr1XW8N6SbykdSmoOMw = Function('return this')();
   if (!__cov_dzjgr1XW8N6SbykdSmoOMw.__coverage__) {
      __cov_dzjgr1XW8N6SbykdSmoOMw.__coverage__ = {};
   }
   __cov_dzjgr1XW8N6SbykdSmoOMw = __cov_dzjgr1XW8N6SbykdSmoOMw.__coverage__;
   if (!__cov_dzjgr1XW8N6SbykdSmoOMw['app/templates/components/donation-icon.hbs']) {
      __cov_dzjgr1XW8N6SbykdSmoOMw['app/templates/components/donation-icon.hbs'] = { "path": "app/templates/components/donation-icon.hbs", "s": {}, "b": {}, "f": {}, "fnMap": {}, "statementMap": {}, "branchMap": {}, "code": ["export default Ember.HTMLBars.template({\"id\":\"7S3t5k4h\",\"block\":\"{\\\"symbols\\\":[],\\\"statements\\\":[[1,[25,\\\"paper-icon\\\",[\\\"card giftcard\\\"],[[\\\"title\\\"],[\\\"donation\\\"]]],false],[0,\\\"\\\\n\\\"]],\\\"hasEval\\\":false}\",\"meta\":{\"moduleName\":\"prison-rideshare-ui/templates/components/donation-icon.hbs\"}});"] };
   }
   __cov_dzjgr1XW8N6SbykdSmoOMw = __cov_dzjgr1XW8N6SbykdSmoOMw['app/templates/components/donation-icon.hbs'];
   exports.default = Ember.HTMLBars.template({ 'id': '7S3t5k4h', 'block': '{"symbols":[],"statements":[[1,[25,"paper-icon",["card giftcard"],[["title"],["donation"]]],false],[0,"\\n"]],"hasEval":false}', 'meta': { 'moduleName': 'prison-rideshare-ui/templates/components/donation-icon.hbs' } });
});