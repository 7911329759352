define('prison-rideshare-ui/models/institution', ['exports', 'ember-data', 'prison-rideshare-ui/utils/dollars'], function (exports, _emberData, _dollars) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });

   var __cov_$B5Sm39f7vOWjeYG7CDcyA = Function('return this')();
   if (!__cov_$B5Sm39f7vOWjeYG7CDcyA.__coverage__) {
      __cov_$B5Sm39f7vOWjeYG7CDcyA.__coverage__ = {};
   }
   __cov_$B5Sm39f7vOWjeYG7CDcyA = __cov_$B5Sm39f7vOWjeYG7CDcyA.__coverage__;
   if (!__cov_$B5Sm39f7vOWjeYG7CDcyA['app/models/institution.js']) {
      __cov_$B5Sm39f7vOWjeYG7CDcyA['app/models/institution.js'] = { "path": "app/models/institution.js", "s": { "1": 0, "2": 0, "3": 0, "4": 0, "5": 0 }, "b": { "1": [0, 0] }, "f": { "1": 0 }, "fnMap": { "1": { "name": "(anonymous_1)", "line": 11, "loc": { "start": { "line": 11, "column": 42 }, "end": { "line": 11, "column": 53 } } } }, "statementMap": { "1": { "start": { "line": 12, "column": 4 }, "end": { "line": 12, "column": 59 } }, "2": { "start": { "line": 14, "column": 4 }, "end": { "line": 18, "column": 11 } }, "3": { "start": { "line": 15, "column": 6 }, "end": { "line": 15, "column": 53 } }, "4": { "start": { "line": 16, "column": 6 }, "end": { "line": 16, "column": 46 } }, "5": { "start": { "line": 17, "column": 6 }, "end": { "line": 17, "column": 22 } } }, "branchMap": { "1": { "line": 15, "type": "binary-expr", "locations": [{ "start": { "line": 15, "column": 21 }, "end": { "line": 15, "column": 46 } }, { "start": { "line": 15, "column": 50 }, "end": { "line": 15, "column": 52 } }] } }, "code": ["import { computed, get } from '@ember/object';", "import DS from 'ember-data';", "import dollars from 'prison-rideshare-ui/utils/dollars';", "", "export default DS.Model.extend({", "  name: DS.attr(),", "  rate: DS.attr('number', {defaultValue: 0}),", "", "  rateDollars: dollars('rate'),", "", "  validationErrors: computed('errors.[]', function() {", "    const attributes = get(this.constructor, 'attributes');", "", "    return attributes._keys.list.reduce((response, key) => {", "      const errors = this.get(`errors.${key}`) || [];", "      response[key] = errors.mapBy('message');", "      return response;", "    }, {});", "  })", "});", ""] };
   }
   __cov_$B5Sm39f7vOWjeYG7CDcyA = __cov_$B5Sm39f7vOWjeYG7CDcyA['app/models/institution.js'];
   var computed = Ember.computed;
   var get = Ember.get;
   exports.default = _emberData.default.Model.extend({ name: _emberData.default.attr(), rate: _emberData.default.attr('number', { defaultValue: 0 }), rateDollars: (0, _dollars.default)('rate'), validationErrors: computed('errors.[]', function () {
         var _this = this;

         __cov_$B5Sm39f7vOWjeYG7CDcyA.f['1']++;__cov_$B5Sm39f7vOWjeYG7CDcyA.s['1']++;var attributes = get(this.constructor, 'attributes');__cov_$B5Sm39f7vOWjeYG7CDcyA.s['2']++;return attributes._keys.list.reduce(function (response, key) {
            __cov_$B5Sm39f7vOWjeYG7CDcyA.s['3']++;var errors = (__cov_$B5Sm39f7vOWjeYG7CDcyA.b['1'][0]++, _this.get('errors.' + key)) || (__cov_$B5Sm39f7vOWjeYG7CDcyA.b['1'][1]++, []);__cov_$B5Sm39f7vOWjeYG7CDcyA.s['4']++;response[key] = errors.mapBy('message');__cov_$B5Sm39f7vOWjeYG7CDcyA.s['5']++;return response;
         }, {});
      }) });
});